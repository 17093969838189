import React, { useState, useLayoutEffect, useEffect } from "react";
import Header from "../Layout/Header";
import NavMob from "../Layout/NavMob";
import { useSelector, useDispatch } from "react-redux";
import { setIdMesaAction } from "../../redux/actions/carritoActions";
import { ConfigurationAction, CheckUserAction } from "../../redux/actions/configurationActions";
import Checks from "./Checks";
import { MetroSpinner } from "react-spinners-kit";

const CheckUser = () => {

  const dispatch = useDispatch();

  const itemsConfiguracion = useSelector((state) => state.configurationsR.itemsConfiguration);
  const idMesaPedido = useSelector((state) => state.carrito.idMesa);
  const rCenterPedido = useSelector((state) => state.carrito.rCenter);

  const checkUser = useSelector((state) => state.configurationsR.checkUser);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const CargarLaConfiguracion = () => dispatch(ConfigurationAction());
    if (!itemsConfiguracion)
      CargarLaConfiguracion();

    let parametros = window.location.search;
    let urlParams = new URLSearchParams(parametros);
    let paramIdMesa = urlParams.get('idMesa');
    let paramRCenter = urlParams.get('revenueCenter');

    let idMesaPOS = JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa
    let rCenterPOS = JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter
    const establecerIdMesa = () => dispatch(setIdMesaAction(idMesaPOS, rCenterPOS))

    if (idMesaPedido == null) {
      if(idMesaPOS) localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
      if(rCenterPOS) localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
      establecerIdMesa();
    }
    //eslint-disable-next-line  
  }, []);

  useEffect(() => {
    if(itemsConfiguracion) dispatch(CheckUserAction(itemsConfiguracion, idMesaPedido, rCenterPedido))
    //eslint-disable-next-line  
  }, [itemsConfiguracion]);

  const useWindowSize = () => {
      const [size, setSize] = useState([0, 0]);
      useLayoutEffect(() => {
          const updateSize = () => {
              setSize([window.innerWidth, window.innerHeight]);
          }
          window.addEventListener("resize", updateSize);
          updateSize();
          return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
  }

  const ShowWindowDimensions = () => {
      const [width] = useWindowSize();
      return width;
  }

  let newWidth = ShowWindowDimensions();
  let isMobile = newWidth < 640 ? true : false;
  let navMenu = newWidth < 1024 ? true : false;

  return (
    <>
      <div className=" xl:w-4/5 m-auto relative" style={{ paddingBottom: isMobile ? "200px" : "0" }}>
        <NavMob show={show} setShow={setShow} navMenu={navMenu} />
        <Header setShow={setShow} />
          <h1 className="uppercase font-bold  w-full m-5">Mi cuenta</h1>
          {
            !checkUser ?
              (<>
                <div className="flex justify-center m-2">
                  {" "}<MetroSpinner color="#000" />{" "}
                </div>
                <p className="text-center m-1">Consultando mi cuenta...</p>
              </>) : checkUser.length ?
                  (<>
                    {checkUser &&
                      <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 px-5">
                          {checkUser.map((check, index) => 
                            <Checks key={index} check={check}/>
                          )}
                      </div>}
                  </>) : (
                    <div className="text-center m-3">
                      Aún no posee una cuenta.
                    </div>
                  )
        }
      </div>
    </>
  )
}

export default CheckUser
