import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { qrUpdateActions } from "../../redux/actions/qrActions";
import { ConfigurationAction } from "../../redux/actions/configurationActions";
import Swal from "sweetalert2";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};
const obligatorio = {
    color: "#dc2626"
};

const ModalUpdateQr = ({ openModal, setOpenModal, RevenueCenterqR, UrlQr, numMesaUpd, idQr }) => {

    const dispatch = useDispatch();
    const itemsConfiguracion = useSelector((state) => state.configurationsR.itemsConfiguration);
    const [numeroMesaUpdate, setNumeroMesaUpdate] = useState(numMesaUpd);
    const [revenueCenter, setRevenueCenter] = useState(RevenueCenterqR);
    const [urlQrUpdate, setUrlQrUpdate] = useState(UrlQr);

    const handlerClick = () => {
        if ((numeroMesaUpdate == "" && numMesaUpd == "") || (urlQrUpdate == "" && UrlQr == "")) {
            Swal.fire(
                '¡Alerta!',
                'Algunos campos son obligatorios.',
                'warning'
            );
        } else {
            dispatch(qrUpdateActions((revenueCenter.trim() == '' ? RevenueCenterqR : revenueCenter) || itemsConfiguracion?.RevenueCenter, 
                numeroMesaUpdate.trim() == '' ? numMesaUpd : numeroMesaUpdate, 
                urlQrUpdate.trim() == '' ? UrlQr : urlQrUpdate, idQr)
            );
            setOpenModal(false);
        }
    };

    const limpiar = () => {
        setOpenModal(false);
        setNumeroMesaUpdate('');
        setUrlQrUpdate('');
    }

    useEffect(() => {
        const cargarConfiguracion = () => dispatch(ConfigurationAction());
        if (!itemsConfiguracion) cargarConfiguracion();
        //eslint-disable-next-line
    }, [])

    return (
        <div style={styles}>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                center={true}
                style
            >

                <div className="styleModal">
                    <h2 className="font-bold mb-5">Actualización del QR</h2>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                        <label className="self-center"><span style={obligatorio}>*</span> Número de Mesa: </label>
                        <input
                            name="numeroMesa"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Número de Mesa"
                            id="idNumeroMesaUpdate"
                            value={numeroMesaUpdate == "" ? numMesaUpd : numeroMesaUpdate}
                            onChange={event => setNumeroMesaUpdate(event.target.value)}
                        />
                        <label className="self-center"> Revenue Center: </label>
                        <input
                            name="revenueCenter"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Revenue Center"
                            id="idRevenueCenter"
                            value={revenueCenter == "" ? RevenueCenterqR : revenueCenter}
                            onChange={event => setRevenueCenter(event.target.value)}
                        />
                        <label className="self-center"><span style={obligatorio}>*</span> Url: </label>
                        <input
                            name="Url"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Url"
                            id="idUrlUpdate"
                            value={urlQrUpdate == "" ? UrlQr : urlQrUpdate}
                            onChange={event => setUrlQrUpdate(event.target.value)}
                        />
                    </div>
                    <div className="flex justify-end">

                        <div className="flex">

                            <button
                                style={{ marginTop: "50px" }}
                                className={`py-1 text-red-600 focus:outline-none hover:text-red-400 px-4`}
                                onClick={(e) => limpiar()} >Cancelar
                            </button>

                            <button
                                style={{ marginTop: "50px" }}
                                className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                onClick={handlerClick} >Actualizar
                                </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalUpdateQr;