import {
    DESCARGAR_CONFIGUTATION,
    ACTUALIZAR_CONFIGURATION,
    DESCARGAR_OPEN_CHECKS,
    DESCARGAR_CHECK_USER
} from "../types";

const initialState = {
    error: false,
    loading: false,
    itemsConfiguration: null,
    openChecks: null,
    checkUser: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTUALIZAR_CONFIGURATION:
            return {
                ...state,
                loading: false,
            };
        case DESCARGAR_CONFIGUTATION:
            return {
                ...state,
                loading: false,
                error: false,
                itemsConfiguration: action.payload
            };
        case DESCARGAR_OPEN_CHECKS:
            return {
                ...state,
                loading: false,
                error: false,
                openChecks: action.payload
            };
        case DESCARGAR_CHECK_USER:
            return {
                ...state,
                loading: false,
                error: false,
                checkUser: action.payload
            };
        default:
            return state;
    }
}
