import React, { useState } from "react";
import Imagen from "../../recursos/LogoFinal.png";
import { useDispatch } from "react-redux";
import { carritoDeleteActions } from "../../redux/actions/carritoActions.js";

import Swal from "sweetalert2";

const ItemCarrito = ({ dish }) => {
	const dispatch = useDispatch();	
	const deleteItem = (dish) => dispatch(carritoDeleteActions(dish));

	const selectItem = () => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "Se eliminará el item del carrito.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sí, eliminar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.value) {
				deleteItem(dish);
			}
		});
	};
	let image = dish.Name2[0].StringText[0].trim() !== "" ? dish.Name2[0].StringText[0] : null
	let price = dish.Price.split(".")[0]

	return (
		<>
			<div className="border asd rounded-lg w-full mb-5">
				<div className="rounded-lg relative imgContainer">
					<img src={image || Imagen} alt="dish" className="rounded-t-md w-full" onError={(e)=>{e.target.onerror = null; e.target.src=Imagen}} />
				</div>
				<div className="m-3">
					<div className="flex mb-2 justify-between">
						<h1 className="titulos font-medium my-auto">{`${dish.Repetido}x ${dish.Name1[0].StringText || "Sin nombre"}`}</h1>
						<div className="flex flex-wrap justify-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="ionicon"
								width="18"
								viewBox="0 0 512 512"
							>
								<title>Time</title>
								<path
									d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z"
									fill="none"
									stroke="#707070"
									strokeMiterlimit="10"
									strokeWidth="32"
								/>
								<path
									fill="none"
									stroke="#707070"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="32"
									d="M256 128v144h96"
								/>
							</svg>
							<p className="parrafos my-auto">10-30</p>
						</div>
					</div>
					<div className="flex mb-2 justify-between">
						<p className="parrafos ">
							{dish.LongDescriptor[0].StringText[0].trim() !== "" ? dish.LongDescriptor[0].StringText : "Sin descripción"}
						</p>
					</div>
					{dish.additions &&
						<div className="flex mb-2">
							<h3 className="my-auto mr-2">Adiciones:</h3>
							<p className="parrafos my-auto">
								{dish.additions}
							</p>
						</div>
					}
					<div className="flex mb-2 justify-between">
						<p className="titulos font-medium my-auto">{price !== "0" ? `$${price}` : ""}</p>
						{!dish.delete && (<button
							className={`px-3 rounded-md py-2 border border-red-600 text-red-600 focus:outline-none hover:bg-red-500 hover:text-white flex items-center`}
							onClick={()=>selectItem()} >
							<svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-1 cursor-pointer bi bi-trash-fill" viewBox="0 0 16 16">
								<title>Elimina</title>
								<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
							</svg>Eliminar
						</button>)}
					</div>
				</div>
			</div>

		</>
	);
};

export default ItemCarrito;
