import React from 'react'

const Checks = ({ check }) => {

  return (
    <div className="border asd rounded-lg w-full mb-5">
      <div className="m-3">
        <div className="flex mb-2 justify-between">
          <h1 className="titulos font-medium my-auto">CheckSeq: </h1>
          <div className="flex flex-wrap justify-center">
            <p className="parrafos my-auto">{check.CheckSeq == null ? "..." : check.CheckSeq}</p>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="flex mb-2 justify-between">
          <h1 className="titulos font-medium my-auto">CheckNum: </h1>
          <div className="flex flex-wrap justify-center">
            <p className="parrafos my-auto">{check.CheckNum == null ? "..." : check.CheckNum}</p>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="flex mb-2 justify-between">
          <h1 className="titulos font-medium my-auto">CheckTotalDue: </h1>
          <div className="flex flex-wrap justify-center">
            <p className="parrafos my-auto">{check.CheckTotalDue == null ? "..." : check.CheckTotalDue}</p>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="flex mb-2 justify-between">
          <h1 className="titulos font-medium my-auto">CheckOpenTime: </h1>
          <div className="flex flex-wrap justify-center">
            <p className="parrafos my-auto">{check.CheckOpenTime == null ? "..." : check.CheckOpenTime}</p>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="flex mb-2 justify-between">
          <h1 className="titulos font-medium my-auto">Nuero de mesa: </h1>
          <div className="flex flex-wrap justify-center">
            <p className="parrafos my-auto">{check.CheckTableObjectNum == null ? "..." : check.CheckTableObjectNum}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checks
