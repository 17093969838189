import {
	AGREGAR_ITEM,
	ERROR_DESCARGA,
	ELIMINAR_ITEM,
	CONSULTAR_FAVORITO,
	ESTABLECER_ID_MESA,
	ESTABLECER_CAMBIOS_PEDIDOS,
	ESTABLECER_CAMBIOS_CARRITOPEDIDO,
	PLATO_SELECCIONADO,
	REQUERIMIENTO_SELECCIONADO,
	CONT_CART,
	AGREGAR_ITEMS
} from "../types";

import {setSessionStorage, removeSessionStorage} from '../../utils/sesionStorage'
const initialState = {
	error: false,
	loading: false,
	items: [],
	favoritos: [],
	idMesa: null,
	rCenter: null,
	idCheckSeq: null,
	idCheckNum: null,
	carritoElementos: [],
	platoSeleccionado: null,
	requerimientos: null,
	cont:  0
};

export default function (state = initialState, action) {
	switch (action.type) {
		case AGREGAR_ITEM:
			let newItem = JSON.parse(JSON.stringify(action.payload));
			newItem.Repetido = 1;
			let flag = false;
			state.items.map((item) => {
				if (
					item.MenuItemDefID[0] === newItem.MenuItemDefID[0] &&
					item.additions === newItem.additions
				) {
					item.Repetido = item.Repetido + 1;
					flag = true;
				}
			});
			const copyItem = flag ? state.items : [...state.items, newItem]
			setSessionStorage('orders', JSON.stringify(copyItem))
			return {
				...state,
				loading: false,
				error: false,
				items: copyItem,
			};
		case AGREGAR_ITEMS:
			setSessionStorage('orders', JSON.stringify(action.payload))
			return {
				...state,
				loading: false,
				error: false,
				items: action.payload,
				cont: action.payload.reduce((acc, item)=>{
					return  acc + item.Repetido
				  }, 0)
			};
		case CONT_CART:
			return {
				...state,
				loading: false,
				error: false,
				cont: state.cont + 1,
			};
		case ERROR_DESCARGA:
			return {
				...state,
				loading: false,
				error: true,
				MenuItems: null,
			};
		case ELIMINAR_ITEM:
			const copyItemFilter = state.items.filter(
				(item) =>
					(item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] &&
						item.additions !== action.payload.additions) ||
					item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]
			)
			if(copyItemFilter.length === 0){
				removeSessionStorage('orders')
			}else{
				setSessionStorage('orders', JSON.stringify(copyItemFilter))
			}
			return {
				...state,
				loading: false,
				items: copyItemFilter,
				cont: state.cont - action.payload.Repetido
			};
		case CONSULTAR_FAVORITO:
			return {
				...state,
				loading: false,
				error: false,
				favoritos: [action.payload],
			};
		case ESTABLECER_ID_MESA:
			return {
				...state,
				idMesa: action.payload.idMesa,
				rCenter: action.payload.rCenter,
			};
		case ESTABLECER_CAMBIOS_PEDIDOS:
			return {
				...state,
				idCheckSeq: action.payload,
				idCheckNum: action.idCheckNum,
			};

		case ESTABLECER_CAMBIOS_CARRITOPEDIDO:
			return {
				...state,
				carritoElementos: action.payload,
			};
		case PLATO_SELECCIONADO:
			return {
				...state,
				platoSeleccionado: action.payload,
			};
		case REQUERIMIENTO_SELECCIONADO:
			return {
				...state,
				requerimientos: !state.requerimientos ? action.payload : [...state.requerimientos, ...action.payload],
			};
		default:
			return state;
	}
}
