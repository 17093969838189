import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { usuarioCreateActions } from "../../redux/actions/usuariosActions";
import Swal from "sweetalert2";
import { MetroSpinner } from "react-spinners-kit";



const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};
const obligatorio = {
    color: "#dc2626"
};


const ModalAddUser = ({ openModal, setOpenModal }) => {

    const dispatch = useDispatch();
    const [nameUser, setNameUser] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [image, setImage] = useState(null);


    const [cargando, setCargando] = useState(false);


    const cloud_name = "duwwaqmaw";
    const upload_preset = "y5wb8b85";
    const handleClick2 = () => {
        setCargando(true);
        const { files } = document.querySelector(".app_uploadInput");
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("upload_preset", upload_preset);
        const options = {
            method: "POST",
            body: formData,
        };
        return fetch(`https://api.Cloudinary.com/v1_1/${cloud_name}/image/upload`, options)
            .then((res) => res.json())
            .then((res) => {
                let auxUrlImage = res.url;
                setImage(auxUrlImage)
                setCargando(false);
            })
            .catch((err) => console.log(err));
    };


    const handlerClick = () => {

        let converPasswordBase64 = btoa(password);

        if (image == null) {
            Swal.fire(
                '¡Alerta!',
                'Debe cargar una imagen.',
                'warning'
            );

        } else if (userName == "" || role == "" || nameUser == "" || password == "") {
            Swal.fire(
                '¡Alerta!',
                'Algunos campos son obligatorios.',
                'warning'
            );
        } else {
            dispatch(usuarioCreateActions(userName, converPasswordBase64, role, nameUser, image));
            setOpenModal(false);
        }

    };

    return (
        <div style={styles}>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                center={true}
                style
            >

                <div className="styleModal">
                    <h2 className="font-bold mb-5">Creación De Usuario</h2>
                    <div className="grid grid-cols-2 gap-2">
                        <label className="self-center"> <span style={obligatorio}>*</span> UserName: </label>
                        <input
                            name="userName"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="User Name"
                            id="idUserName"
                            value={userName}
                            onChange={event => setUserName(event.target.value)}
                        />
                        <label className="self-center"> <span style={obligatorio}>*</span> Contraseña: </label>
                        <input
                            name="Password"
                            type="password"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Contraseña"
                            id="idPass"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                        />
                        <label className="self-center"> <span style={obligatorio}>*</span> Rol: </label>
                        <select
                            name="Rol"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Rol"
                            id="idRole"
                            value={role}
                            onChange={event => setRole(event.target.value)}
                        >
                            <option value="">Seleccionar rol</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Cliente">Cliente</option>
                        </select>
                        <label className="self-center"> <span style={obligatorio}>*</span>Nombre: </label>
                        <input
                            name="Nombre"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Nombre Completo"
                            id="idNombre"
                            value={nameUser}
                            onChange={event => setNameUser(event.target.value)}
                        />
                    </div>
                    <div className="flex flex-wrap mt-3 items-center justify-between">
                        <input type="file" className="app_uploadInput" />
                        <button className="app_uploadButton my-2 px-3 rounded-md py-2 text-white bg-green-600 focus:outline-none hover:bg-green-500" onClick={handleClick2}>Subir imagen</button>
                    </div>

                    {cargando == true ? (
                        <>
                            <div className="flex justify-center m-2">
                                {" "}<MetroSpinner color="#000" />{" "}
                            </div>
                            <p className="text-center m-1">Subiendo Imagen.</p>
                        </>
                    ) : null
                    }

                    <div className="flex justify-end">

                        <div className="flex ">

                            <button
                                style={{ marginTop: "50px" }}
                                className={`py-1 text-red-600 focus:outline-none hover:text-red-400 px-4`}
                                onClick={() => setOpenModal(false)}>Cancelar
                            </button>
                            {cargando == true ? (
                                <>
                                    <button
                                        disabled
                                        style={{ marginTop: "50px" }}
                                        className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                        onClick={handlerClick} >Espera...
                                </button>
                                </>
                            ) : <>
                                <button
                                    style={{ marginTop: "50px" }}
                                    className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                    onClick={handlerClick} >Guardar
                                </button>
                            </>
                            }

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    );
};

export default ModalAddUser;
