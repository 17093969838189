import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { qrDeleteActions } from "../../redux/actions/qrActions.js";
import ModalQrUpdate from "../Layout/ModalQrUpdate";
import QRcode from 'qrcode.react';

import Swal from "sweetalert2";

const ItemQr = ({ dish }) => {


    const dispatch = useDispatch();
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [RevenueCenterUpdate, setRevenueCenterUpdate] = useState('');
    const [UrlUpdate, setUrlUpdate] = useState('');
    const [numMesaUpd, setNumMesaUpd] = useState('');


    const EliminarQr = (idEliminarQr) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Se eliminará el Qr con el Id: " + idEliminarQr + "  del sistema.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                dispatch(qrDeleteActions(idEliminarQr));
            }
        });
    };

    const ActualizarQr = (RevenueCenter, Url) => {
        setRevenueCenterUpdate(RevenueCenter);
        setUrlUpdate(Url);
        setNumMesaUpd(dish.Mesa)
        setOpenModalUpdate(true);
    }


    // Opciones del QR como iniciar y descargar el qr
    const [qr, setQr] = useState("");
    const handleChange = (event) => {
        setQr(event.target.value);
    };
    const downloadQR = (idQrDescarga) => {
        const canvas = document.getElementById("QrCodeId" + idQrDescarga);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "Qr.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="border asd rounded-lg w-full mb-5 ">
            <div className="rounded-lg relative" style={{textAlign: "-webkit-center"}}>
                <QRcode
                    id={"QrCodeId" + dish.Id}
                    value={dish.Url + "?idMesa=" + dish.Mesa + "&revenueCenter=" + dish.RevenueCenter}
                    size={320}
                    includeMargin={true}
                />
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between">
                    <h1 className="titulos font-medium my-auto">Número de Mesa:</h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.Mesa == null ? "..." : dish.Mesa}</p>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between">
                    <h1 className="titulos font-medium my-auto">Revenue Center:</h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.RevenueCenter == null ? "..." : dish.RevenueCenter}</p>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between flex-wrap">
                    <h1 className="titulos font-medium my-auto">Url:</h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.Url == null ? "..." : dish.Url}</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-between mx-3" style={{bottom: "0"}}>
                <button
                    className={`py-1 text-yellow-600 focus:outline-none hover:text-yellow-400 `}
                    onClick={() => downloadQR(dish.Id)}
                >
                    Descargar
                </button>
                <div className="flex justify-center items-center gap-2">
                    <svg onClick={() => ActualizarQr(dish.RevenueCenter, dish.Url)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0d6efd" className="cursor-pointer bi bi-pencil-fill" viewBox="0 0 16 16">
                        <title>Editar</title>
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                    <svg onClick={() => EliminarQr(dish.Id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" className="cursor-pointer bi bi-trash-fill" viewBox="0 0 16 16">
                        <title>Eliminar</title>
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                </div>
            </div>
            <ModalQrUpdate idQr={dish.Id} openModal={openModalUpdate} setOpenModal={setOpenModalUpdate} RevenueCenterqR={RevenueCenterUpdate} numMesaUpd={numMesaUpd} UrlQr={UrlUpdate} />
        </div>
    )

};

export default ItemQr;
