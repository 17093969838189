import React, { useState, useEffect } from "react";
import Dishes from "./Dishes";
import DishesMob from "./DishesMob";
import { useSelector, useDispatch } from "react-redux";
import { MetroSpinner } from "react-spinners-kit";
import Paginacion from "../../utils/Paginacion";

const Dish = ({
	isMobile,
	selected,
	busqueda,
	menuItemDefinitions,
	menuItemPrice,
	menuItemClass,
	showFavorites
}) => {
	//Current User
	const currentUser = useSelector((state) => state.login.currentUser);
	const favoritosItems = useSelector((state) => state.carrito.favoritos);
	const cargando = useSelector((state) => state.menu.loading);

	let menuItems = menuItemDefinitions;

	if (menuItems && menuItemPrice) {
		menuItems.map((item) => {
			let itemAux = menuItemPrice.filter(
				(item_) => item_.MenuItemDefID[0] === item.MenuItemDefID[0]
			);
			item.Price = itemAux.length ? itemAux[0].Price[0] : "0";
			return true;
		});
	}

	if(showFavorites && menuItems){
		if(favoritosItems && favoritosItems.length){
			let favoritesIds = []
			let favoritesItems = []
			favoritosItems[0].map(fav => favoritesIds.push(fav.MenuItemDefID))

			menuItemClass.forEach(item=> {
				if(item.clase.trim() !== ""){
					item.platos.forEach(dish=>{
						if(favoritesIds.includes(dish.MenuItemDefID[0])) favoritesItems.push(dish)
					})
				} 
			})
			menuItems = favoritesItems
			if (busqueda.trim() !== "") {
				let searchFavorites = []
				menuItems.forEach(_dish=>{
					if(_dish.Name1[0].StringText[0].toLowerCase().includes(busqueda.toLowerCase()) ||
					_dish.LongDescriptor[0].StringText[0].toLowerCase().includes(busqueda.toLowerCase())){
						searchFavorites.push(_dish)
					}
				})
				menuItems = searchFavorites

			}
		}else menuItems = null
	}else if (busqueda.trim() !== "" && menuItems) {
		let searchItems = []
		menuItemClass.forEach(item => {
				if(item.platos){
					item.platos.forEach(_dish=>{
						if(_dish.Name1[0].StringText[0].toLowerCase().includes(busqueda.toLowerCase()) ||
						_dish.LongDescriptor[0].StringText[0].toLowerCase().includes(busqueda.toLowerCase())){
							searchItems.push(_dish)
						}
					})
				}
			});
		menuItems = searchItems
	}else	if (selected.name === "TODOS" && menuItems) {
		let allItems = []
		menuItemClass.forEach(item=> {
			if(item.clase.trim() !== "") allItems = allItems.concat(item.platos)
		})
		menuItems = allItems
	} else if (selected.name !== "TODOS" && menuItems) {
		let menuitemsAux = []
		menuItemClass.forEach(item=> {
			if(item.clase.trim() == selected.name.trim()){
				menuitemsAux = item.platos
			}
		})
		menuItems = menuitemsAux
	}

	if(menuItems && menuItems.length)	menuItems = menuItems.sort((a, b) => ((a.Name1[0].StringText[0].trim() > b.Name1[0].StringText[0].trim()) ? 1 : ((a.Name1[0].StringText[0].trim() <  b.Name1[0].StringText[0].trim()) ? -1 : 0)))

	// Paginacion
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = menuItems
		? menuItems.slice(indexOfFirstPost, indexOfLastPost)
		: [];

	// Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	
	return (
		<>
			<h1 className="uppercase font-bold m-5">
				{showFavorites ? "Mis favoritos" : busqueda ? "Búsqueda" : selected.name || "Sin nombre"}
			</h1>
			{cargando ? (
				<>
					<div className="flex justify-center m-2">
						{" "}
						<MetroSpinner color="#000" />{" "}
					</div>
					<p className="text-center m-1">Cargando platos...</p>
				</>
			) : currentPosts.length !== 0 ? (
				<>
					<div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 px-5">
						{currentPosts.map((_dish, i) => {
							return isMobile ? (
								<DishesMob
									currentPosts={currentPosts}
									key={i}
									dish={_dish}
									favoritos={favoritosItems}
									currenUs={currentUser}
								/>
							) : (
								<Dishes
									currentPosts={currentPosts}
									key={i}
									dish={_dish}
									favoritos={favoritosItems}
									currenUs={currentUser}
								/>
							);
						})}
					</div>
					<Paginacion
						postsPerPage={postsPerPage}
						totalPosts={menuItems.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</>
			) : (
				<div className="text-center m-3">
					No hay platos para esta categoría.
				</div>
			)}
		</>
	);
};

export default Dish;
