import React from "react";
import Filtros from "./components/Layout/Filtros";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Carrito from "./components/Cart/Carrito";
import OpenChecks from "./components/Checks/OpenChecks";
import Usuarios from "./components/Users/Usuarios";
import Login from "./components/Login/Index";
import Imagenes from "./components/Images/Imagenes";
import Qr from "./components/Qr/Qr";
import Configuration from "./components/Config/Config";
import CheckUser from "./components/Checks/CheckUser";
import Redirect from "./components/Layout/Redirect";


function App() {
	return (
		<>
			<Router>
				<Provider store={store}>
					<Switch>
						<Route exact path="/" component={Filtros} />
						<Route exact path="/Carrito" component={Carrito} />
						<Route exact path="/OpenChecks" component={OpenChecks} />
						<Route exact path="/CheckUser" component={CheckUser} />
						<Route exact path="/Usuarios" component={Usuarios} />
						<Route exact path="/Login" component={Login} />
						<Route exact path="/Imagenes" component={Imagenes} />
						<Route exact path="/Qr" component={Qr} />
						<Route exact path="/Config" component={Configuration} />
						<Redirect/>
					</Switch>
				</Provider>
			</Router>
		</>
	);
}

export default App;
