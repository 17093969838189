import {
    DESCARGAR_CONFIGUTATION,
    ACTUALIZAR_CONFIGURATION,
    DESCARGAR_OPEN_CHECKS,
    DESCARGAR_CHECK_USER
} from "../types";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export function ConfigurationAction() {
    return async (dispatch) => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const respuesta = await clienteAxios.post(
                `/Config?item=GetConfig`,
                config
            );
            dispatch(descargarConfiguration(respuesta.data));
        } catch (error) {
        }
    };
}

export function configurationUpdateActions(UrlServer, RevenueCenter, EmployeeObjectNum) {
    return async (dispatch) => {
        try {

            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                urlServer: UrlServer,
                revenueCenter: RevenueCenter,
                employeObjectNum: EmployeeObjectNum
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.put(
                `/Config?item=UpdateConfig`,
                data,
                config
            );
            Swal.fire({
                title: "Correcto",
                text: "La configuración se ha actualizado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });
        } catch (error) {
        }
    };
}


export function restoreConfigurationActions() {
    return async (dispatch) => {
        try {
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo="
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.put(
                `/Config?item=RestoreConfig`,
                data,
                config
            );
            Swal.fire({
                title: "Correcto",
                text: "La configuración se ha restaurado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });
        } catch (error) {
        }
    };
}

const descargarConfiguration = (ConfigurationItem) => ({
    type: DESCARGAR_CONFIGUTATION,
    payload: ConfigurationItem
});

export function OpenChecksAction(itemsConfiguracion, revenueCenter) {
    return async (dispatch) => {
        try {
            let data = {
				urlServer: itemsConfiguracion.UrlServer,
				revenueCenter: revenueCenter || itemsConfiguracion.RevenueCenter,
				employeeObjectNum: itemsConfiguracion.EmployeeObjectNum,
			};
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const respuesta = await clienteAxios.post(
                `MenuItem?item=GetOpenChecks`,
                data,
                config
            );
            console.log('respuesta', respuesta)
            let aux = respuesta.data["soap:Envelope"]["soap:Body"]["GetOpenChecksResponse"]["openChecks"]["CheckSummary"]?.SimphonyPosApi_CheckSummary
            aux = aux ? Array.isArray(aux) ? aux : [aux] : [];
            dispatch(OpenChecks(aux));
        } catch (e) {
            console.log(e)
        }
    };
}

const OpenChecks = (data) => ({
    type: DESCARGAR_OPEN_CHECKS,
    payload: data
});

export function CheckUserAction(itemsConfiguracion, idMesaPedido, revenueCenter) {
    return async (dispatch) => {
        try {
            let data = {
				urlServer: itemsConfiguracion.UrlServer,
				revenueCenter: revenueCenter || itemsConfiguracion.RevenueCenter,
				employeeObjectNum: itemsConfiguracion.EmployeeObjectNum,
			};
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `MenuItem?item=GetOpenChecks`,
                data,
                config
            );
            let aux = respuesta.data["soap:Envelope"]["soap:Body"]["GetOpenChecksResponse"]["openChecks"]["CheckSummary"]?.SimphonyPosApi_CheckSummary
            aux = aux ? Array.isArray(aux) ? aux : [aux] : [];
            aux = aux.filter(x => x.CheckTableObjectNum == idMesaPedido)
            dispatch(CheckUser(aux));
        } catch (e) {
            console.log(e)
        }
    };
}

const CheckUser = (data) => ({
    type: DESCARGAR_CHECK_USER,
    payload: data
});

