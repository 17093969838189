import React, { useState, useLayoutEffect, useEffect } from "react";
import Header from "../Layout/Header";
import NavMob from "../Layout/NavMob";
import { useSelector, useDispatch } from "react-redux";
import { ConfigurationAction } from "../../redux/actions/configurationActions";
import { setIdMesaAction } from "../../redux/actions/carritoActions";
import { MetroSpinner } from "react-spinners-kit";
import ItemConfig from "./itemConfig";
import { restoreConfigurationActions } from "../../redux/actions/configurationActions";

const Config = () => {

    const dispatch = useDispatch();

    const itemsConfiguracion = useSelector((state) => state.configurationsR.itemsConfiguration);
    const idMesaPedido = useSelector((state) => state.carrito.idMesa);
    const [show, setShow] = useState(false);

    const restoreConfiguration = () => {
        dispatch(restoreConfigurationActions());
    }

    useEffect(() => {
        const cargarConfiguracion = () => dispatch(ConfigurationAction());
        if (!itemsConfiguracion)
            cargarConfiguracion();

        let parametros = window.location.search;
        let urlParams = new URLSearchParams(parametros);
        let paramIdMesa = urlParams.get('idMesa');
        let paramRCenter = urlParams.get('revenueCenter');

        let idMesaPOS = JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa
        let rCenterPOS = JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter
        const establecerIdMesa = () => dispatch(setIdMesaAction(idMesaPOS, rCenterPOS))

        if (idMesaPedido == null) {
            if(idMesaPOS) localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
            if(rCenterPOS) localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
            establecerIdMesa();
        }
        //eslint-disable-next-line
    }, [])


    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }
    const ShowWindowDimensions = () => {
        const [width] = useWindowSize();
        return width;
    }
    let newWidth = ShowWindowDimensions();
    let isMobile = newWidth < 640 ? true : false;
    let navMenu = newWidth < 1024 ? true : false;
    let thClass = 'border border-black text-black px-4 py-2'

    return (
        <div className=" xl:w-4/5 m-auto relative" style={{ paddingBottom: isMobile ? "200px" : "0" }}>

            <NavMob show={show} setShow={setShow} navMenu={navMenu} />
            <Header setShow={setShow} />

            <div className="flex justify-between items-center">
                <h1 className="uppercase font-bold m-5">Administración de Configuración</h1>
                <button className="px-3 rounded-md py-2 text-white bg-yellow-600 focus:outline-none hover:bg-yellow-500 mx-5"  onClick={(e) => restoreConfiguration()} >Restaurar </button>
            </div>
            {
                itemsConfiguracion == null ?
                    (<>
                        <div className="flex justify-center m-2">
                            {" "}<MetroSpinner color="#000" />{" "}
                        </div>
                        <p className="text-center m-1">Consultando Configuración.</p>
                    </>) : (
                        <>
                            {itemsConfiguracion &&
                                <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 px-5">
                                        <ItemConfig item={itemsConfiguracion}/>
                                </div>
                            }
                        </>
                    )
            }
        </div >
    );
}

export default Config;