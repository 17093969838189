import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModalConfigurationUpdate from "../Layout/ModalConfigUpdate";

const ItemConfig = ({ item }) => {

    const dispatch = useDispatch();
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [UrlServerUpdate, setUrlServerUpdate] = useState('');
    const [RevenueCenterUpdate, setRevenueCenterUpdate] = useState('');
    const [EmployeeObjectNumUpdate, setEmployeeObjectNumUpdate] = useState('');

    const ActualizarConfiguracion = (url, Revenue, EmployeeObject) => {
        setUrlServerUpdate(url);
        setRevenueCenterUpdate(Revenue);
        setEmployeeObjectNumUpdate(EmployeeObject);
        setOpenModalUpdate(true);
    }

    return (
        <>
            <div className="border asd rounded-lg w-full mb-5">
                <div className="m-3">
                    <div className="flex mb-2 justify-between">
                        <h1 className="titulos font-medium my-auto">UrlServer: </h1>
                        <div className="flex flex-wrap justify-center">
                            <p className="parrafos my-auto">{item.UrlServer}</p>
                        </div>
                    </div>
                </div>
                <div className="m-3">
                    <div className="flex mb-2 justify-between">
                        <h1 className="titulos font-medium my-auto">RevenueCenter: </h1>
                        <div className="flex flex-wrap justify-center">
                            <p className="parrafos my-auto">{item.RevenueCenter}</p>
                        </div>
                    </div>
                </div>
                <div className="m-3">
                    <div className="flex mb-2 justify-between">
                        <h1 className="titulos font-medium my-auto">EmployeeObjectNum: </h1>
                        <div className="flex flex-wrap justify-center">
                            <p className="parrafos my-auto">{item.EmployeeObjectNum}</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end items-center gap-2 m-3">
                    <button
                        className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500 flex items-center`}
                        onClick={()=>ActualizarConfiguracion(item.UrlServer, item.RevenueCenter, item.EmployeeObjectNum)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFF" className="cursor-pointer bi bi-pencil-fill mr-2" viewBox="0 0 16 16">
                                <title>Editar</title>
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                            </svg>Editar
                    </button>
                    
                </div>
            </div>
            <ModalConfigurationUpdate openModal={openModalUpdate} setOpenModal={setOpenModalUpdate} UrlServerUpdate={UrlServerUpdate} RevenueCenterUpdate={RevenueCenterUpdate} EmployeeObjectNumUpdate={EmployeeObjectNumUpdate} />
        </>    
    )

};
export default ItemConfig;
