import React, { useState } from "react";
import AppStore from "../../recursos/Grupo 63.png";
import GooglePlay from "../../recursos/Grupo 64.png";
import 'swiper/swiper-bundle.css'


const itemsBanner = ({ dish }) => {

    return (
        <>
            {
                dish.Tipo == "Banner" &&
                <div id="fondo" className="sm:border sm:rounded-lg m-0 sm:mx-5 mb-5 relative" style={{ background: `url(${dish.Url})` }}>
                    {/* <div className="text-white text-left text-4xl sm:m-5 sm:m-16 ">
                        <span className="hidden sm:inline">
                            <p>Lorem Ipsum </p>
                            <p>Dolor Sit Amet </p>
                            <p>Consectetur.</p>
                        </span>
                        <span className="inline sm:hidden textHeader p-3"><p className=" text-2xl">Lorem Ipsum Dolor Sit Amet Consectetur.</p></span>
                        <div className="Logos my-16 hidden sm:flex">
                            <img src={AppStore} alt="App Store Logo" className="mr-2 cursor-pointer" />
                            <img src={GooglePlay} alt="Google Play Logo" className="ml-2 cursor-pointer" />
                        </div>
                    </div> */}
                </div>
            }
        </>
    );
};

export default itemsBanner;