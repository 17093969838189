import {
	DESCARGAR_USUARIO,
	ACTUALIZAR_USUARIO,
	AGREGAR_USUARIO,
	ELIMINA_USUARIO
} from "../types";

const initialState = {
	error: false,
	loading: false,
	itemsUsuarios: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case AGREGAR_USUARIO:
			return {
				...state,
				loading: false,
				error: false,
				itemsUsuarios: [...state.itemsUsuarios, action.payload]
			};
		case ACTUALIZAR_USUARIO:
			return {
				...state,
				loading: false,
				items: state.items.filter(
					(item) => ((item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] && item.additions !== action.payload.additions) || (item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]))),
			};
		case DESCARGAR_USUARIO:
			return {
				...state,
				loading: false,
				error: false,
				itemsUsuarios: action.payload
			};
		case ELIMINA_USUARIO:
			return {
				...state,
				loading: false,
				items: state.items.filter(
					(item) => ((item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] && item.additions !== action.payload.additions) || (item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]))),
			};
		default:
			return state;
	}
}
