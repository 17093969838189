import React, { useState, useLayoutEffect, useEffect, useMemo } from "react";
import ItemCarritoMob from "./ItemCarritoMob";
import ItemCarrito from "./ItemCarrito";
import Header from "../Layout/Header";
import NavMob from "../Layout/NavMob";
import { useSelector, useDispatch } from "react-redux";
import { MenuCuentaAction } from "../../redux/actions/menuActions";
import { PostTransactionAction, setIdMesaAction, AddToExistingCheck, getCheckDetail } from "../../redux/actions/carritoActions";
import { ConfigurationAction } from "../../redux/actions/configurationActions";
import { MetroSpinner } from "react-spinners-kit";

const Carrito = () => {

	const dispatch = useDispatch();
	const requerimientos = useSelector((state) => state.carrito.requerimientos);
	const itemsCarrito = useSelector((state) => state.carrito.items);
	const TotalsResponse = useSelector((state) => state.menu.cuentaMenu);
	const cargando = useSelector((state) => state.menu.loading);
	const idMesaPedido = useSelector((state) => state.carrito.idMesa);
	
	const rCenterPedido = useSelector((state) => state.carrito.rCenter);

	const [show, setShow] = useState(false);
	const itemsConfiguracion = useSelector((state) => state.configurationsR.itemsConfiguration);
	const objCarritos = useSelector((state) => state.carrito.carritoElementos);
	const openChecks = useSelector((state) => state.configurationsR.openChecks);
	
	useEffect(() => {
		const CargarLaConfiguracion = () => dispatch(ConfigurationAction());
		if (!itemsConfiguracion)
			CargarLaConfiguracion();

		let parametros = window.location.search;
		let urlParams = new URLSearchParams(parametros);
		let paramIdMesa = urlParams.get('idMesa');
		let paramRCenter = urlParams.get('revenueCenter');

		let idMesaPOS = JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa
		let rCenterPOS = JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter
		const establecerIdMesa = () => dispatch(setIdMesaAction(idMesaPOS, rCenterPOS))

		if (idMesaPedido == null) {
			if(idMesaPOS) localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
			if(rCenterPOS) localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
			establecerIdMesa();
		}

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const cargarMenu = () => dispatch(MenuCuentaAction(itemsCarrito, itemsConfiguracion, requerimientos, rCenterPedido));
		if (itemsCarrito && itemsCarrito.length)
			cargarMenu();
		//eslint-disable-next-line
	}, [itemsCarrito])

	const useWindowSize = () => {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			const updateSize = () => {
				setSize([window.innerWidth, window.innerHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}

	const ShowWindowDimensions = () => {
		const [width] = useWindowSize();
		return width;
	}

	let openCheck = useMemo(() => 
		(openChecks?.length) ? openChecks.find(check => check.CheckTableObjectNum === idMesaPedido) : false,
		[openChecks, idMesaPedido]
	)
	const idCheckNum = useSelector((state) => state.carrito.idCheckNum) || openCheck?.CheckNum || null;
	const idCheckSeq = useSelector((state) => state.carrito.idCheckSeq) || openCheck?.CheckSeq || null;

	// Hacer Pedido
	const HacerPedido = async () => {
		let xmlCarritosItems = "";
		if ((idCheckNum == null && idCheckSeq == null) || !openCheck) {
			// let precioTotal = TotalsResponse.pTotalsResponse.TotalsTotalDue;
			let objetosCarrito = TotalsResponse?.ppMenuItems?.SimphonyPosApi_MenuItem || [];
			if(objetosCarrito.length > 1){
				objetosCarrito.map((item) => {
					xmlCarritosItems += `<SimphonyPosApi_MenuItem>\
											<Condiments/>\
											<MenuItem>\
												<ItemDiscount>\
													<DiscObjectNum>0</DiscObjectNum>\
												</ItemDiscount>\
												<MiObjectNum>${item.MenuItem.MiObjectNum}</MiObjectNum>\
												<MiOverridePrice />\
												<MiReference />\
												<MiWeight />\
												<MiMenuLevel>1</MiMenuLevel>\
												<MiSubLevel>1</MiSubLevel>\
												<MiPriveLevel>0</MiPriveLevel>\
											</MenuItem>\
										</SimphonyPosApi_MenuItem>`;
				});
			}else{
			xmlCarritosItems += `<SimphonyPosApi_MenuItem>\
											<Condiments/>\
											<MenuItem>\
												<ItemDiscount>\
													<DiscObjectNum>0</DiscObjectNum>\
												</ItemDiscount>\
												<MiObjectNum>${objetosCarrito.MenuItem.MiObjectNum}</MiObjectNum>\
												<MiOverridePrice />\
												<MiReference />\
												<MiWeight />\
												<MiMenuLevel>1</MiMenuLevel>\
												<MiSubLevel>1</MiSubLevel>\
												<MiPriveLevel>0</MiPriveLevel>\
											</MenuItem>\
										</SimphonyPosApi_MenuItem>`;
			}

			dispatch(PostTransactionAction(objetosCarrito, xmlCarritosItems, idMesaPedido, itemsConfiguracion, rCenterPedido));
		} else {
			let itemsCheck = await getCheckDetail(itemsConfiguracion, idCheckNum, idCheckSeq)
			
			let objetosCarrito = TotalsResponse.ppMenuItems.SimphonyPosApi_MenuItem;

			let auxCarrito = JSON.parse(JSON.stringify(objetosCarrito))

			for (const iterator of itemsCheck) {
				let flag = false
				auxCarrito = auxCarrito.filter(y => {
					if((y.MenuItem.MiObjectNum !== iterator.obj[0]) || flag) return y
					if((y.MenuItem.MiObjectNum === iterator.obj[0]) && !flag) flag = true
				})
			}

			auxCarrito.map((item) => {
				xmlCarritosItems += `<SimphonyPosApi_MenuItem>\
										<Condiments/>\
										<MenuItem>\
											<ItemDiscount>\
												<DiscObjectNum>0</DiscObjectNum>\
											</ItemDiscount>\
											<MiObjectNum>${item.MenuItem.MiObjectNum}</MiObjectNum>\
											<MiOverridePrice />\
											<MiReference />\
											<MiWeight />\
											<MiMenuLevel>1</MiMenuLevel>\
											<MiSubLevel>1</MiSubLevel>\
											<MiPriveLevel>0</MiPriveLevel>\
										</MenuItem>\
									</SimphonyPosApi_MenuItem>`;
			});

			dispatch(AddToExistingCheck(objetosCarrito, xmlCarritosItems, idMesaPedido, itemsConfiguracion, idCheckNum, idCheckSeq, rCenterPedido));
		}

	};

	let newWidth = ShowWindowDimensions();
	let isMobile = newWidth < 640 ? true : false;
	let navMenu = newWidth < 1024 ? true : false;

	return (
		<div className=" xl:w-4/5 m-auto relative" style={{ paddingBottom: isMobile ? "200px" : "0" }}>
			<NavMob show={show} setShow={setShow} navMenu={navMenu} />
			<Header setShow={setShow} />
			<h1 className="uppercase font-bold m-5">Mi carrito</h1>
			{cargando ? (
				<>
					<div className="flex justify-center m-2">
						{" "}<MetroSpinner color="#000" />{" "}
					</div>
					<p className="text-center m-1">Cargando carrito...</p>
				</>
			) : itemsCarrito && itemsCarrito.length > 0 ? (
				<>
					<div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 px-5">
						{itemsCarrito.map((_dish, index) => {
							return isMobile ? (
								<ItemCarritoMob key={index} name={_dish.name} dish={_dish} />
							) : (
								<ItemCarrito key={index} name={_dish.name} dish={_dish} />
							);
						})}
					</div>
					{TotalsResponse &&
						<>
							<div className="text-center mx-3 mt-1">
								Sub Total: ${TotalsResponse.pTotalsResponse.TotalsSubTotal && TotalsResponse.pTotalsResponse.TotalsSubTotal.split(".")[0]}
							</div>
							<div className="text-center mx-3">
								Propina: ${TotalsResponse.pTotalsResponse.TotalsAutoSvcChgTotals && TotalsResponse.pTotalsResponse.TotalsAutoSvcChgTotals.split(".")[0]}
							</div>
							<div className="text-center mx-3 mb-1">
								Total: ${TotalsResponse.pTotalsResponse.TotalsTotalDue && TotalsResponse.pTotalsResponse.TotalsTotalDue.split(".")[0]}
							</div>
						</>
					}
					<div className="flex justify-center">
						<button
							type="button"
							className="mb-4 mx-1 bg-gray-800 w-1/3 rounded-md p-2 text-center text-white hover:bg-gray-900 focus:outline-none"
							onClick={(e) => HacerPedido()}
						>
							{
								(idCheckNum != null && idCheckSeq != null ? "Actualizar Pedido" : "Hacer Pedido")
							}
						</button>
					</div>
				</>
			) : (
				<div className="text-center m-3">
					Aún no hay items en el carrito, agregue uno.
				</div>
			)}
		</div>
	);
};

export default Carrito;
