import React, { useState, useLayoutEffect, useEffect, useMemo } from "react";
import Dish from "../Dishes/Dish";
import NavMob from "./NavMob";
import Tags from "../Tags/Tags";
import TagsMob from "../Tags/TagsMob";
import Header from "./Header";
import { BtnHelpers } from "./StyleComp";
import { useSelector, useDispatch } from "react-redux";
import {
  MenuItemsAction,
  AskWaiterAction,
} from "../../redux/actions/menuActions";
import { dishesGetFavoritesActions } from "../../redux/actions/carritoActions";
import {
  setIdMesaAction,
  carritoAddsActions,
  getCheckDetail,
} from "../../redux/actions/carritoActions";

import {
  ConfigurationAction,
  OpenChecksAction,
} from "../../redux/actions/configurationActions";
import { getSessionStorage } from "../../utils/sesionStorage";
const Filtros = () => {
  const dispatch = useDispatch();
  const menuItemClass = useSelector((state) => state.menu.menuItemClass);
  const menuItemDefinitions = useSelector(
    (state) => state.menu.menuItemDefinitions
  );
  const itemsCarrito = useSelector((state) => state.carrito.items);
  const menuItemPrice = useSelector((state) => state.menu.menuItemPrice);
  const favoritosItems = useSelector((state) => state.carrito.favoritos);
  const itemsConfiguracion = useSelector(
    (state) => state.configurationsR.itemsConfiguration
  );
  const idMesaPedido = useSelector((state) => state.carrito.idMesa);
  const rCenterPedido = useSelector((state) => state.carrito.rCenter);
  const openChecks = useSelector((state) => state.configurationsR.openChecks);

  const [selected, setSelected] = useState({ name: "TODOS", num: "TODOS" });
  const [show, setShow] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const [showFavorites, setshowFavorites] = useState(false);
  const [localUserStorage, setLocalUserStorage] = useState(null);

  let openCheck = useMemo(
    () =>
      openChecks?.length
        ? openChecks.find((check) => check.CheckTableObjectNum === idMesaPedido)
        : false,
    [openChecks, idMesaPedido]
  );
  const idCheckNum =
    useSelector((state) => state.carrito.idCheckNum) ||
    openCheck?.CheckNum ||
    null;
  const idCheckSeq =
    useSelector((state) => state.carrito.idCheckSeq) ||
    openCheck?.CheckSeq ||
    null;

  const getCheck = async (itemsConfiguracion, idCheckNum, idCheckSeq) => {
    let itemsCheck = await getCheckDetail(
      itemsConfiguracion,
      idCheckNum,
      idCheckSeq
    );
    itemsCheck = itemsCheck.filter((item) => {
      return item.AuthEmployeeID;
    });
    if (
      itemsCheck &&
      (itemsCheck.length > 0) &
        (itemsCheck.length !==
          itemsCarrito.filter((carri) => carri.AuthEmployeeID).length)
    ) {
      const array2 = [];
      const prueb = [];
      itemsCheck.forEach((arr, index) => {
        let tes = prueb.find((x) => x.Name[0] === arr.Name[0]);
        if (!tes) {
          array2.push(arr.Name[0]);
          arr.Repetido = 1;
          const name = [{ StringText: arr.Name[0] }];
          const name2 = [{ StringText: [arr.Name[0]] }];
          const long = [{ StringText: [""] }];
          const arrCopy = {
            ...arr,
            Name1: name,
            LongDescriptor: long,
            additions: null,
            Price: arr.DscntItmzrID[0],
            Name2: name2,
            delete: true,
          };
          prueb.push(arrCopy);
        } else {
          tes.Repetido = (tes.Repetido || 0) + 1;
        }
      });
      if (prueb && prueb.length > 0) {
        dispatch(carritoAddsActions(prueb));
      }
    }
  };
  useEffect(() => {
    let userStorage = localStorage.getItem("localUserObj");
    let userInfoStorage = JSON.parse(userStorage);

    setLocalUserStorage(userInfoStorage);
    if (userInfoStorage != null) {
      const cargarFavoritos = () =>
        dispatch(
          dishesGetFavoritesActions(
            userInfoStorage.UserName,
            userInfoStorage.password
          )
        );
      if (favoritosItems.length == 0) cargarFavoritos();
    }

    const CargarLaConfiguracion = () => dispatch(ConfigurationAction());
    if (!itemsConfiguracion) CargarLaConfiguracion();

    let parametros = window.location.search;
    let urlParams = new URLSearchParams(parametros);
    let paramIdMesa = urlParams.get("idMesa");
    let paramRCenter = urlParams.get("revenueCenter");

    let idMesaPOS =
      JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa;
    let rCenterPOS =
      JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter;
    const establecerIdMesa = () =>
      dispatch(setIdMesaAction(idMesaPOS, rCenterPOS));

    if (idMesaPedido == null) {
      if (idMesaPOS)
        localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
      if (rCenterPOS)
        localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
      establecerIdMesa();
    }

    const items = getSessionStorage("orders");
    if (items && items.length > 0) {
      dispatch(carritoAddsActions(items));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cargarMenu = () =>
      dispatch(MenuItemsAction(itemsConfiguracion, rCenterPedido));
    if (!menuItemDefinitions || !menuItemClass || !menuItemPrice) cargarMenu();
    //eslint-disable-next-line
  }, [itemsConfiguracion, rCenterPedido]);

  useEffect(() => {
    if (itemsConfiguracion)
      dispatch(OpenChecksAction(itemsConfiguracion, rCenterPedido));
    //eslint-disable-next-line
  }, [itemsConfiguracion]);

  useEffect(() => {
    if (idCheckNum) {
      getCheck(itemsConfiguracion, idCheckNum, idCheckSeq);
    }
  }, [idCheckNum, idCheckSeq]);
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  const ShowWindowDimensions = () => {
    const [width] = useWindowSize();
    return width;
  };

  let newWidth = ShowWindowDimensions();
  let isMobile = newWidth < 640 ? true : false;
  let navMenu = newWidth < 1024 ? true : false;
  // let styleOrange = { background: "#ef4c00" };

  return (
    <div className=" xl:w-11/12 2xl:w-4/5 m-auto relative">
      <NavMob show={show} setShow={setShow} navMenu={navMenu} />
      <Header setShow={setShow} />

      <div className="mx-5 flex flex-wrap justify-between">
        <h1 className="uppercase font-bold sm:my-auto w-full sm:w-1/2 my-5">
          Nuestro Menú
        </h1>
        <div className="flex w-full sm:w-1/2 justify-end my-1">
          <div className="flex w-full justify-end relative inline-block ">
            <input
              className="rounded-full relative focus:outline-none focus:shadow-2xl py-2 pl-5 pr-10 w-full"
              id="search"
              type="text"
              placeholder="Buscar por nombre"
              style={{ background: "#f4f9ff" }}
              onChange={(e) => setBusqueda(e.target.value)}
              value={busqueda}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 23"
              style={{ right: "0px" }}
              className="iconActive outline-none w-6 pt-2.5 pb-2 colorPT absolute cursor-pointer mr-3 bi bi-search"
            >
              <path
                id="ic_search_white"
                d="M309.923,64.308h-.968l-.363-.363a7.572,7.572,0,0,0,1.936-5.081,7.864,7.864,0,1,0-7.864,7.864,7.572,7.572,0,0,0,5.081-1.936l.363.363v.968l6.049,6.049,1.815-1.815Zm-7.259,0a5.444,5.444,0,1,1,5.444-5.444A5.422,5.422,0,0,1,302.664,64.308Z"
                transform="translate(-294.8 -51)"
                fill="#ef4c00"
              />
            </svg>
          </div>
          {localUserStorage && (
            <div className="colorP outline-none rounded-full flex items-center justify-center ml-2 h-10 w-11 cursor-pointer px-2 sm:px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setshowFavorites(!showFavorites)}
                width="18"
                height="18"
                fill="#FFFF"
                className="iconActive outline-none w-5 text-white cursor-pointer ionicon  bi bi-heart-fill"
                viewBox="0 0 16 16"
              >
                <title>Favoritos</title>
                <path
                  fillRule="evenodd"
                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="flex flex-wrap justify-between my-5 inline sm:hidden w-full">
          <BtnHelpers className="helpers cursor-pointer focus:outline-none">
            <p
              onClick={() =>
                dispatch(
                  AskWaiterAction(
                    menuItemDefinitions,
                    itemsConfiguracion,
                    idMesaPedido,
                    idCheckNum,
                    idCheckSeq,
                    !!openCheck
                  )
                )
              }
            >
              Pedir al mesero
            </p>
          </BtnHelpers>
          <BtnHelpers className="helpers cursor-pointer focus:outline-none mx-1">
            <p>Pedir la cuenta</p>
          </BtnHelpers>
          <BtnHelpers className="helpers cursor-pointer focus:outline-none">
            <p>Comentarios</p>
          </BtnHelpers>
        </div>
      </div>
      {!showFavorites &&
        (isMobile ? (
          <TagsMob
            selected={selected}
            setSelected={setSelected}
            menuItemClass={menuItemClass}
          />
        ) : (
          <Tags
            selected={selected}
            setSelected={setSelected}
            menuItemClass={menuItemClass}
          />
        ))}
      <Dish
        showFavorites={showFavorites}
        isMobile={isMobile}
        selected={selected}
        busqueda={busqueda}
        menuItemDefinitions={menuItemDefinitions}
        menuItemPrice={menuItemPrice}
        menuItemClass={menuItemClass}
      />
    </div>
  );
};

export default Filtros;
