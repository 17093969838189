import React from "react";

const Imagen = ({ img, eliminarImagen}) => {

	let tdClass = 'border-t border-b text-center p-1'

	return (
		<>
			<tr >
				<td className={tdClass}> {img.Id} </td>
				<td className={tdClass}>
          <a
						href={img.Url}
            target="_blank"
						className="m-2 px-2 pt-2 border-black border-b hover:text-blue-900 hover:border-blue-900 focus:outline-none"
					>
						Ver imagen
					</a> 
         </td>
				<td className={tdClass}> {img.Tipo} </td>
				<td className={tdClass} style={{textAlign: "-webkit-center"}}>
          <svg onClick={() => eliminarImagen(img.Id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" className="my-auto cursor-pointer bi bi-trash-fill" viewBox="0 0 16 16">
              <title>Eliminar</title>
              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
				</td>
			</tr>
		</>
	);
};

export default Imagen;