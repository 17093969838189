import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const ItemsLogo = ({ dish }) => {
    const history = useHistory();

    return (
        <>
            <div className="sm:w-4/12">
                <img src={dish.Url} alt="si" onClick={() => history.push(`/`)} className="w-full h-full cursor-pointer" />
            </div>
        </>
    );
};

export default ItemsLogo;