import {
	DESCARGA_MENU_ITEMS,
	ERROR_DESCARGA,
	CUENTA_MENU,
	COMENZAR_DESCARGA_ITEMS,
} from "../types";

const initialState = {
	error: false,
	loading: false,
	menuItemDefinitions: null,
	menuItemClass: null,
	menuItemPrice: null,
	originalClass: null,
	cuentaMenu: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case COMENZAR_DESCARGA_ITEMS:
			return {
				...state,
				loading: true,
			};
		case DESCARGA_MENU_ITEMS:
			return {
				...state,
				loading: false,
				error: false,
				menuItemDefinitions: action.payload.menuItemDefinitions,
				menuItemClass: action.payload.menuItemClass,
				menuItemPrice: action.payload.menuItemPrice,
				originalClass: action.payload.originalClass,
			};
		case CUENTA_MENU:
			return {
				...state,
				loading: false,
				error: true,
				cuentaMenu: action.payload,
			};
		case ERROR_DESCARGA:
			return {
				...state,
				loading: false,
				error: true,
				menuItemDefinitions: null,
			};
		default:
			return state;
	}
}
