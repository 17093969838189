import React, { useState, useEffect } from "react";
import heartLine from "../../recursos/corazon-linea.svg";
import heartFull from "../../recursos/corazon-lleno.svg";
import defaultImage from "../../recursos/LogoFinal.png";
import { useDispatch } from "react-redux";
import { carritoAddActions, dishesAddFavoritesActions, setDishSelectedAction } from "../../redux/actions/carritoActions.js";
import ModalAdd from "../Layout/Modal";

const DishesMob = ({ dish ,favoritos, currentPosts}) => {

	let userStorage = localStorage.getItem("localUserObj");
	let userInfoStorage = JSON.parse(userStorage);

	const [localUserStorage, setLocalUserStorage] = useState(userInfoStorage);


	const dispatch = useDispatch();
	const addItem = (dish) => dispatch(carritoAddActions(dish));

	const [handleHeart, setHandleHeart] = useState(false);
	const [openModal, setOpenModal] = useState(false)

	const handleEventHeart = () => {
		if (localUserStorage != null) {
			dispatch(dishesAddFavoritesActions(localUserStorage.UserName, localUserStorage.password, dish.MenuItemDefID));
			setHandleHeart(!handleHeart);
		}
		else {
			alert("Tiene que estar logeado para agregar a favoritos");
		}
	};

	const addItemHandler = () => {
		dispatch(setDishSelectedAction(dish));
		setOpenModal(true);
	}

	const handleSave = (additions) => {
		let _dish = JSON.parse(JSON.stringify(dish));
		_dish.additions = (additions && additions.length) ? additions.join(", ") : null;
		addItem(_dish);
	}

	const pruebas = () => {

		if (localUserStorage != null && favoritos.length > 0) {
			let aux = favoritos[0].filter(item => {
				return item.MenuItemDefID == dish.MenuItemDefID && item.Id_User == localUserStorage.Id
			});
			if (aux.length != 0) {
				setHandleHeart(true);
			}
			if(aux.length == 0 && handleHeart) {
				setHandleHeart(false);
			}
		}
	}

	useEffect(() => {
		pruebas();
	}, [currentPosts]);

	let image = dish.Name2[0].StringText[0].trim() !== "" ? dish.Name2[0].StringText[0] : null
	let price = dish.Price.split(".")[0]

	return (
		<div className="border asd rounded-lg w-full flex sm:inline mb-3 ">
			<div className="flex rounded-lg pl-3 py-3 w-1/3">
				<img src={image || defaultImage} alt="dish" className="rounded-t-md my-auto" onError={(e)=>{e.target.onerror = null; e.target.src=defaultImage}} />
			</div>
			<div className="m-3 w-2/3">
				<h1 className="titulos font-medium my-auto pl-1 text-center">{dish.Name1[0].StringText || "Sin nombre"}</h1>

				<div className="flex flex-wrap justify-between">
					<img src={handleHeart ? heartFull : heartLine} alt="line heart" onClick={handleEventHeart} className="cursor-pointer" />
					<div className="flex flex-wrap justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" className="ionicon" width="18" viewBox="0 0 512 512">
							<title>Time</title>
							<path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="32" />
							<path fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 128v144h96" />
						</svg>
						<p className="parrafos my-auto">10-30</p>
					</div>
				</div>

				<div className="flex">
					<p className="parrafos w-full sm:w-3/4 my-1">
						{dish.LongDescriptor[0].StringText[0].trim() !== "" ? dish.LongDescriptor[0].StringText : "Sin descripción"}
					</p>
				</div>
				<div className="flex justify-between">
					<p className=" w-2/3 titulos font-medium my-auto">{price !== "0" ? `$${price}` : ""}</p>
					<div className=" flex justify-center px-1.5 py-1 rounded cursor-pointer w-1/3" style={{ background: "#ef4c00", width: "100px" }} onClick={addItemHandler}>
						<div className="rounded-full flex items-center justify-center  my-auto">
							<svg id="card_group" data-name="card group" xmlns="http://www.w3.org/2ef4c00/svg" width="15.137" height="12.969" viewBox="0 0 15.137 12.969">
								<path id="Trazado_1569" data-name="Trazado 1569" d="M33.754,101.958h2.63l-.462-.351c.077.233.153.466.23.7q.278.841.554,1.682.333,1.011.668,2.025.29.879.578,1.758c.094.284.184.57.281.853l0,.013a.479.479,0,1,0,.925-.254c-.077-.233-.153-.466-.23-.7q-.278-.841-.554-1.682-.333-1.013-.668-2.027-.29-.879-.578-1.758c-.094-.284-.182-.57-.281-.853l0-.013a.494.494,0,0,0-.462-.351h-2.63a.479.479,0,0,0,0,.958Z" transform="translate(-33.276 -101)" fill="#fff" />
								<path id="Trazado_1570" data-name="Trazado 1570" d="M288.374,541.8q-.388.692-.778,1.383c-.037.067-.075.132-.112.2a.484.484,0,0,0,.414.72h7.315c.337,0,.676.006,1.014,0h.014a.479.479,0,0,0,0-.958h-7.315c-.337,0-.677-.01-1.014,0H287.9l.414.72q.388-.692.778-1.383c.037-.067.075-.133.112-.2a.479.479,0,1,0-.827-.482Z" transform="translate(-283.364 -534.53)" fill="#fff" />
								<circle id="Elipse_1102" data-name="Elipse 1102" cx="1.088" cy="1.088" r="1.088" transform="translate(10.303 10.474)" fill="#fff" />
								<path id="Trazado_1571" data-name="Trazado 1571" d="M658.4,738.381a1.43,1.43,0,0,0,.939,1.326,1.408,1.408,0,0,0,1.535-.409,1.407,1.407,0,1,0-2.474-.917.319.319,0,1,0,.639,0c0-.026,0-.053,0-.078a.24.24,0,0,1,0-.034c0-.064,0,.021,0,.019a.616.616,0,0,1,.038-.156c0-.013.014-.067.026-.07s-.029.059-.011.027c.006-.013.013-.026.017-.038a1.469,1.469,0,0,1,.081-.136c.03-.046-.043.05.008-.01.016-.018.032-.037.049-.054l.053-.051.024-.021c.011-.01.058-.032.016-.014s-.01.008,0,0l.035-.024c.018-.011.035-.022.054-.032s.049-.026.075-.038c.008,0,.042-.021,0,0s.016,0,.021-.006a.984.984,0,0,1,.134-.038l.043-.008c.035-.008-.03,0-.03,0,.029,0,.059-.006.088-.006a1.29,1.29,0,0,1,.157,0c.059,0-.061-.011.013,0,.029,0,.056.013.085.019s.048.014.072.022l.04.014s-.059-.03-.027-.011.094.048.139.077l.035.024c.011.008.035.014,0,0s-.011-.01,0,0l.032.027a1.429,1.429,0,0,1,.109.112c.048.053-.022-.037.008.01.013.021.027.04.04.061s.026.043.037.064l.014.029c.03.057.006,0,0-.008.019.006.04.121.046.142l.014.064c.013.061,0-.019,0-.021s0,.045,0,.054a1.089,1.089,0,0,1,0,.145.4.4,0,0,0,0,.045c0,.032-.019.027,0-.019a.486.486,0,0,0-.018.085c-.011.045-.029.088-.042.133s.029-.054.008-.018a.159.159,0,0,0-.013.029c-.013.026-.026.049-.04.073s-.026.042-.04.062c-.043.065.03-.032-.006.011a1.244,1.244,0,0,1-.109.113c-.006.006-.045.048-.056.048s.065-.045.014-.013l-.026.018c-.045.029-.091.053-.137.077s.057-.019-.011,0l-.07.024-.073.019-.032.006c-.07.016.051,0,.01,0a1.28,1.28,0,0,1-.157.006c-.025,0-.051,0-.078,0-.01,0-.048,0,0,0s-.011,0-.022,0a1.214,1.214,0,0,1-.164-.048c-.01,0-.042-.019,0,0l-.019-.01c-.026-.013-.049-.026-.074-.04s-.046-.03-.07-.046,0,.013.014.013c-.006,0-.027-.022-.032-.027a1.155,1.155,0,0,1-.118-.118.19.19,0,0,1-.027-.032c0,.006.038.054.013.014-.016-.024-.032-.046-.046-.07s-.03-.056-.045-.083c-.034-.066.016.051-.01-.021a1.18,1.18,0,0,1-.046-.166.1.1,0,0,0-.006-.032c.024.053,0,.045,0,.008s0-.067,0-.1a.323.323,0,0,0-.647,0Z" transform="translate(-648.415 -726.819)" fill="#fff" />
								<circle id="Elipse_1103" data-name="Elipse 1103" cx="1.088" cy="1.088" r="1.088" transform="translate(4.798 10.474)" fill="#fff" />
								<path id="Trazado_1572" data-name="Trazado 1572" d="M313.7,738.381a1.43,1.43,0,0,0,.939,1.326,1.408,1.408,0,0,0,1.535-.409,1.407,1.407,0,1,0-2.474-.917.319.319,0,1,0,.639,0c0-.026,0-.053,0-.078a.24.24,0,0,1,0-.034c0-.064,0,.021,0,.019a.618.618,0,0,1,.038-.156c0-.013.014-.067.026-.07s-.029.059-.011.027a.409.409,0,0,0,.018-.038,1.463,1.463,0,0,1,.081-.136c.03-.046-.043.05.008-.01.016-.018.032-.037.049-.054l.053-.051.024-.021c.011-.01.057-.032.016-.014s-.01.008,0,0l.035-.024c.018-.011.035-.022.054-.032s.049-.026.075-.038c.008,0,.042-.021,0,0s.016,0,.021-.006a.987.987,0,0,1,.134-.038l.043-.008c.035-.008-.03,0-.03,0,.029,0,.059-.006.088-.006a1.289,1.289,0,0,1,.157,0c.059,0-.061-.011.013,0,.029,0,.056.013.085.019s.048.014.072.022l.04.014s-.059-.03-.027-.011.094.048.139.077l.035.024c.011.008.035.014,0,0s-.011-.01,0,0l.032.027a1.433,1.433,0,0,1,.109.112c.048.053-.022-.037.008.01.013.021.027.04.04.061s.026.043.037.064l.014.029c.03.057.006,0,0-.008.019.006.04.121.046.142l.014.064c.013.061,0-.019,0-.021s0,.045,0,.054a1.1,1.1,0,0,1,0,.145.394.394,0,0,0,0,.045c0,.032-.019.027,0-.019a.5.5,0,0,0-.018.085c-.011.045-.029.088-.042.133s.029-.054.008-.018a.157.157,0,0,0-.013.029c-.013.026-.026.049-.04.073s-.026.042-.04.062c-.043.065.03-.032-.006.011a1.229,1.229,0,0,1-.109.113c-.006.006-.045.048-.056.048s.065-.045.014-.013l-.026.018c-.045.029-.091.053-.137.077s.057-.019-.011,0l-.07.024-.073.019-.032.006c-.07.016.051,0,.01,0a1.279,1.279,0,0,1-.157.006c-.026,0-.051,0-.078,0-.01,0-.048,0,0,0s-.011,0-.022,0a1.208,1.208,0,0,1-.164-.048c-.01,0-.042-.019,0,0l-.019-.01c-.026-.013-.05-.026-.073-.04s-.046-.03-.07-.046,0,.013.014.013c-.006,0-.027-.022-.032-.027a1.165,1.165,0,0,1-.118-.118.19.19,0,0,1-.027-.032c0,.006.038.054.013.014-.016-.024-.032-.046-.046-.07s-.03-.056-.045-.083c-.034-.066.016.051-.01-.021a1.168,1.168,0,0,1-.046-.166.1.1,0,0,0-.006-.032c.024.053,0,.045,0,.008s0-.067,0-.1a.323.323,0,0,0-.647,0Z" transform="translate(-309.218 -726.819)" fill="#fff" />
								<g id="Grupo_3911" data-name="Grupo 3911" transform="translate(3.454 2.182)">
									<path id="Trazado_1573" data-name="Trazado 1573" d="M280.737,258l-1.939,5h-7.45l-1.648-5Z" transform="translate(-269.378 -257.674)" fill="#fff" />
									<path id="Trazado_1574" data-name="Trazado 1574" d="M260.573,237.849q-.328.846-.655,1.691-.522,1.349-1.043,2.7l-.24.62.308-.235h-6.534c-.3,0-.6-.013-.906,0h-.013l.308.235q-.28-.85-.559-1.7-.443-1.344-.885-2.689c-.069-.206-.136-.412-.2-.62l-.308.4h10.59c.144,0,.288,0,.43,0h.019a.319.319,0,1,0,0-.639h-10.59c-.144,0-.287,0-.43,0h-.019a.322.322,0,0,0-.308.4q.28.85.559,1.7.443,1.344.885,2.689c.069.206.136.412.2.62a.33.33,0,0,0,.308.235h6.534c.3,0,.6.011.906,0h.013a.332.332,0,0,0,.308-.235q.328-.846.655-1.691.522-1.349,1.043-2.7l.24-.62a.32.32,0,0,0-.224-.393A.328.328,0,0,0,260.573,237.849Z" transform="translate(-249.522 -237.611)" fill="#fff" />
								</g>
							</svg>
						</div>
						<p className="text-sm text-white ml-1 my-auto">Añadir</p>
					</div>
				</div>
			</div>
			{openModal && 
					<ModalAdd
						openModal={openModal}
						setOpenModal={setOpenModal}
						handleSave={handleSave}
					/>
				}
		</div>
	);
};

export default DishesMob;
