import React, { useState, useEffect, useMemo } from "react";
import AppStore from "../../recursos/Grupo 63.png";
import GooglePlay from "../../recursos/Grupo 64.png";
import Imagen from "../../recursos/LogoFinal.png";
import perfil from "../../recursos/perfil.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ImagenesBannerAction } from "../../redux/actions/imagenesActions";
import { ImagenesLogosAction } from "../../redux/actions/imagenesActions";
import { AskWaiterAction } from "../../redux/actions/menuActions";
import ItemsLogo from "../Images/itemsLogo";
import ItemsBanner from "../Images/itemsBanner";
import { OpenChecksAction } from "../../redux/actions/configurationActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import {removeSessionStorage} from '../../utils/sesionStorage'
import 'swiper/swiper-bundle.css'

const Header = ({ setShow }) => {

	const dispatch = useDispatch();
	const itemsImagenes = useSelector((state) => state.imagenes.itemsImagenesBanner);
	const itemsImagenesLogo = useSelector((state) => state.imagenes.itemsImagenesLogo);
	const menuItems = useSelector((state) => state.menu.menuItemDefinitions);
	const idMesaPedido = useSelector((state) => state.carrito.idMesa);
	const rCenterPedido = useSelector((state) => state.carrito.rCenter);
	const itemsConfiguracion = useSelector((state) => state.configurationsR.itemsConfiguration);
	const openChecks = useSelector((state) => state.configurationsR.openChecks);
	const contItem = useSelector((state) => state.carrito.cont);
	const [localUserStorage, setLocalUserStorage] = useState(null);
	const history = useHistory();

	let userInfoStorage = [];
	useEffect(() => {

		const cargaImagenesBanners = () => dispatch(ImagenesBannerAction());
		const cargarLogos = () => dispatch(ImagenesLogosAction());
		

		if (!itemsImagenes) cargaImagenesBanners();
		if (!itemsImagenesLogo) cargarLogos();

		let userStorage = localStorage.getItem("localUserObj");
		userInfoStorage = JSON.parse(userStorage);
		setLocalUserStorage(userInfoStorage);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if(itemsConfiguracion) dispatch(OpenChecksAction(itemsConfiguracion, rCenterPedido))
		//eslint-disable-next-line  
	  }, [itemsConfiguracion]);

	const logOutUserLogin = () => {
		removeSessionStorage('orders')
		localStorage.removeItem("localUserObj");
		history.push(`/`);
	};

	let openCheck = useMemo(() => 
		(openChecks?.length) ? openChecks.find(check => check.CheckTableObjectNum === idMesaPedido) : false,
		[openChecks, idMesaPedido]
	)

	const idCheckNum = useSelector((state) => state.carrito.idCheckNum) || openCheck?.CheckNum || null;
	const idCheckSeq = useSelector((state) => state.carrito.idCheckSeq) || openCheck?.CheckSeq || null;

	return (
		<div className="text-center justify-between ">

			<div className="flex justify-between mx-5">
				<svg xmlns='http://www.w3.org/2000/svg' className='iconActive ionicon my-auto inline lg:hidden cursor-pointer' width="40" viewBox='0 0 512 512' onClick={() => setShow(true)} >
					<title>Menu</title><path fill='none' stroke='currentColor' strokeLinecap='round'
						strokeMiterlimit='10' strokeWidth='32' d='M80 160h352M80 256h352M80 352h352' />
				</svg>
				{
					itemsImagenesLogo != null ?
						(
							<>
								{
									itemsImagenesLogo.length > 0 ? (
										itemsImagenesLogo.map((_dish, index) => {
											return (
												<div className="w-3/12" style={{textAlign: "-webkit-center", alignSelf: "center"}}>
													<ItemsLogo key={_dish.Id} name={_dish.Tipo} dish={_dish} />
												</div>
											)
										})
									) : <div className="w-3/12">
										<img src={Imagen} alt="else" onClick={() => history.push(`/`)} className="w-full h-full cursor-pointer" />
									</div>
								}
							</>
						) : (
							<div className="w-3/12" style={{textAlign: "-webkit-center", alignSelf: "center"}}>
								<div className="sm:w-4/12">
									<img src={Imagen} alt="else" onClick={() => history.push(`/`)} className="w-full h-full cursor-pointer" />
								</div>
							</div>
						)
				}

				{
					localUserStorage != null ?
						(
							<>
								<div className="flex my-5 ">
									<p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => logOutUserLogin()}> Log out</p>

									{
										localUserStorage.Rol == "Administrator" ? (
											<>
												<p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/OpenChecks`)}>Cuentas abiertas</p>
												<p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/Usuarios`)}>Usuarios</p>
												<p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/Imagenes`)}>Imagenes</p>
												<p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/Qr`)}>QRS</p>
												<p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/Config`)}>Configuración</p>
											</>
										) : (null)
									}
									<p className="my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => dispatch(AskWaiterAction(menuItems, itemsConfiguracion, idMesaPedido, idCheckNum, idCheckSeq, rCenterPedido, !!openCheck))}>Pedir al Mesero</p>
									<p className={`${localUserStorage.Rol == "Administrator" ? "mx-2" : "mx-5"} my-auto cursor-pointer lg:inline hidden titulos PC`} style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/CheckUser`)}>Pedir la Cuenta</p>
									{/* <p className="my-auto cursor-pointer lg:inline hidden titulos CO" style={{ borderBottomColor: "#ef4c00" }}>Comentarios</p> */}
									{/* <p className="mx-2 my-auto cursor-pointer lg:inline hidden titulos PC" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/Favorite`)} >Favoritos</p> */}

									<div className="ml-5 cursor-pointer border-r border-black pr-2 my-auto sm:inline hidden" style={{textAlign: "-webkit-center"}}>
										<img src={localUserStorage.Url != "" ? localUserStorage.Url : perfil} alt="Imagen perfil" className="w-10 h-10" style={{ width: "auto" }} />
									</div>
									<div className="flex ml-2">
										<div className="rounded-full flex items-center justify-center carrito my-auto relative" onClick={() => history.push(`/Carrito`)}>
											{
												contItem > 0 && (
													<div class="absolute bottom-5 left-5" style={{ 
													'background': 'rgb(239, 76, 0)',
													'border-radius': '1026px',
													'color':' rgb(255, 255, 255)',
													'width': '20px',
													'height': '20px',
													'align-content': 'stretch',
													'display': 'flex',
													'align-items': 'center',
													'justify-content': 'center',
												}}>{contItem}</div>
												)
											}
											
											<svg id="card_group" data-name="card group" xmlns="http://www.w3.org/2ef4c00/svg" className="svgCarrito cursor-pointer" width="15.137" height="12.969" viewBox="0 0 15.137 12.969">
												<path id="Trazado_1569" data-name="Trazado 1569" d="M33.754,101.958h2.63l-.462-.351c.077.233.153.466.23.7q.278.841.554,1.682.333,1.011.668,2.025.29.879.578,1.758c.094.284.184.57.281.853l0,.013a.479.479,0,1,0,.925-.254c-.077-.233-.153-.466-.23-.7q-.278-.841-.554-1.682-.333-1.013-.668-2.027-.29-.879-.578-1.758c-.094-.284-.182-.57-.281-.853l0-.013a.494.494,0,0,0-.462-.351h-2.63a.479.479,0,0,0,0,.958Z" transform="translate(-33.276 -101)" fill="#ef4c00" />
												<path id="Trazado_1570" data-name="Trazado 1570" d="M288.374,541.8q-.388.692-.778,1.383c-.037.067-.075.132-.112.2a.484.484,0,0,0,.414.72h7.315c.337,0,.676.006,1.014,0h.014a.479.479,0,0,0,0-.958h-7.315c-.337,0-.677-.01-1.014,0H287.9l.414.72q.388-.692.778-1.383c.037-.067.075-.133.112-.2a.479.479,0,1,0-.827-.482Z" transform="translate(-283.364 -534.53)" fill="#ef4c00" />
												<circle id="Elipse_1102" data-name="Elipse 1102" cx="1.088" cy="1.088" r="1.088" transform="translate(10.303 10.474)" fill="#ef4c00" />
												<path id="Trazado_1571" data-name="Trazado 1571" d="M658.4,738.381a1.43,1.43,0,0,0,.939,1.326,1.408,1.408,0,0,0,1.535-.409,1.407,1.407,0,1,0-2.474-.917.319.319,0,1,0,.639,0c0-.026,0-.053,0-.078a.24.24,0,0,1,0-.034c0-.064,0,.021,0,.019a.616.616,0,0,1,.038-.156c0-.013.014-.067.026-.07s-.029.059-.011.027c.006-.013.013-.026.017-.038a1.469,1.469,0,0,1,.081-.136c.03-.046-.043.05.008-.01.016-.018.032-.037.049-.054l.053-.051.024-.021c.011-.01.058-.032.016-.014s-.01.008,0,0l.035-.024c.018-.011.035-.022.054-.032s.049-.026.075-.038c.008,0,.042-.021,0,0s.016,0,.021-.006a.984.984,0,0,1,.134-.038l.043-.008c.035-.008-.03,0-.03,0,.029,0,.059-.006.088-.006a1.29,1.29,0,0,1,.157,0c.059,0-.061-.011.013,0,.029,0,.056.013.085.019s.048.014.072.022l.04.014s-.059-.03-.027-.011.094.048.139.077l.035.024c.011.008.035.014,0,0s-.011-.01,0,0l.032.027a1.429,1.429,0,0,1,.109.112c.048.053-.022-.037.008.01.013.021.027.04.04.061s.026.043.037.064l.014.029c.03.057.006,0,0-.008.019.006.04.121.046.142l.014.064c.013.061,0-.019,0-.021s0,.045,0,.054a1.089,1.089,0,0,1,0,.145.4.4,0,0,0,0,.045c0,.032-.019.027,0-.019a.486.486,0,0,0-.018.085c-.011.045-.029.088-.042.133s.029-.054.008-.018a.159.159,0,0,0-.013.029c-.013.026-.026.049-.04.073s-.026.042-.04.062c-.043.065.03-.032-.006.011a1.244,1.244,0,0,1-.109.113c-.006.006-.045.048-.056.048s.065-.045.014-.013l-.026.018c-.045.029-.091.053-.137.077s.057-.019-.011,0l-.07.024-.073.019-.032.006c-.07.016.051,0,.01,0a1.28,1.28,0,0,1-.157.006c-.025,0-.051,0-.078,0-.01,0-.048,0,0,0s-.011,0-.022,0a1.214,1.214,0,0,1-.164-.048c-.01,0-.042-.019,0,0l-.019-.01c-.026-.013-.049-.026-.074-.04s-.046-.03-.07-.046,0,.013.014.013c-.006,0-.027-.022-.032-.027a1.155,1.155,0,0,1-.118-.118.19.19,0,0,1-.027-.032c0,.006.038.054.013.014-.016-.024-.032-.046-.046-.07s-.03-.056-.045-.083c-.034-.066.016.051-.01-.021a1.18,1.18,0,0,1-.046-.166.1.1,0,0,0-.006-.032c.024.053,0,.045,0,.008s0-.067,0-.1a.323.323,0,0,0-.647,0Z" transform="translate(-648.415 -726.819)" fill="#ef4c00" />
												<circle id="Elipse_1103" data-name="Elipse 1103" cx="1.088" cy="1.088" r="1.088" transform="translate(4.798 10.474)" fill="#ef4c00" />
												<path id="Trazado_1572" data-name="Trazado 1572" d="M313.7,738.381a1.43,1.43,0,0,0,.939,1.326,1.408,1.408,0,0,0,1.535-.409,1.407,1.407,0,1,0-2.474-.917.319.319,0,1,0,.639,0c0-.026,0-.053,0-.078a.24.24,0,0,1,0-.034c0-.064,0,.021,0,.019a.618.618,0,0,1,.038-.156c0-.013.014-.067.026-.07s-.029.059-.011.027a.409.409,0,0,0,.018-.038,1.463,1.463,0,0,1,.081-.136c.03-.046-.043.05.008-.01.016-.018.032-.037.049-.054l.053-.051.024-.021c.011-.01.057-.032.016-.014s-.01.008,0,0l.035-.024c.018-.011.035-.022.054-.032s.049-.026.075-.038c.008,0,.042-.021,0,0s.016,0,.021-.006a.987.987,0,0,1,.134-.038l.043-.008c.035-.008-.03,0-.03,0,.029,0,.059-.006.088-.006a1.289,1.289,0,0,1,.157,0c.059,0-.061-.011.013,0,.029,0,.056.013.085.019s.048.014.072.022l.04.014s-.059-.03-.027-.011.094.048.139.077l.035.024c.011.008.035.014,0,0s-.011-.01,0,0l.032.027a1.433,1.433,0,0,1,.109.112c.048.053-.022-.037.008.01.013.021.027.04.04.061s.026.043.037.064l.014.029c.03.057.006,0,0-.008.019.006.04.121.046.142l.014.064c.013.061,0-.019,0-.021s0,.045,0,.054a1.1,1.1,0,0,1,0,.145.394.394,0,0,0,0,.045c0,.032-.019.027,0-.019a.5.5,0,0,0-.018.085c-.011.045-.029.088-.042.133s.029-.054.008-.018a.157.157,0,0,0-.013.029c-.013.026-.026.049-.04.073s-.026.042-.04.062c-.043.065.03-.032-.006.011a1.229,1.229,0,0,1-.109.113c-.006.006-.045.048-.056.048s.065-.045.014-.013l-.026.018c-.045.029-.091.053-.137.077s.057-.019-.011,0l-.07.024-.073.019-.032.006c-.07.016.051,0,.01,0a1.279,1.279,0,0,1-.157.006c-.026,0-.051,0-.078,0-.01,0-.048,0,0,0s-.011,0-.022,0a1.208,1.208,0,0,1-.164-.048c-.01,0-.042-.019,0,0l-.019-.01c-.026-.013-.05-.026-.073-.04s-.046-.03-.07-.046,0,.013.014.013c-.006,0-.027-.022-.032-.027a1.165,1.165,0,0,1-.118-.118.19.19,0,0,1-.027-.032c0,.006.038.054.013.014-.016-.024-.032-.046-.046-.07s-.03-.056-.045-.083c-.034-.066.016.051-.01-.021a1.168,1.168,0,0,1-.046-.166.1.1,0,0,0-.006-.032c.024.053,0,.045,0,.008s0-.067,0-.1a.323.323,0,0,0-.647,0Z" transform="translate(-309.218 -726.819)" fill="#ef4c00" />
												<g id="Grupo_3911" data-name="Grupo 3911" transform="translate(3.454 2.182)">
													<path id="Trazado_1573" data-name="Trazado 1573" d="M280.737,258l-1.939,5h-7.45l-1.648-5Z" transform="translate(-269.378 -257.674)" fill="#ef4c00" />
													<path id="Trazado_1574" data-name="Trazado 1574" d="M260.573,237.849q-.328.846-.655,1.691-.522,1.349-1.043,2.7l-.24.62.308-.235h-6.534c-.3,0-.6-.013-.906,0h-.013l.308.235q-.28-.85-.559-1.7-.443-1.344-.885-2.689c-.069-.206-.136-.412-.2-.62l-.308.4h10.59c.144,0,.288,0,.43,0h.019a.319.319,0,1,0,0-.639h-10.59c-.144,0-.287,0-.43,0h-.019a.322.322,0,0,0-.308.4q.28.85.559,1.7.443,1.344.885,2.689c.069.206.136.412.2.62a.33.33,0,0,0,.308.235h6.534c.3,0,.6.011.906,0h.013a.332.332,0,0,0,.308-.235q.328-.846.655-1.691.522-1.349,1.043-2.7l.24-.62a.32.32,0,0,0-.224-.393A.328.328,0,0,0,260.573,237.849Z" transform="translate(-249.522 -237.611)" fill="#ef4c00" />
												</g>
											</svg>
										</div>
										<p className={`${localUserStorage.Rol == "Administrator" ? "mx-2" : "mx-5"} my-auto cursor-pointer xl:inline hidden titulos`} style={{ color: "#ef4c00" }} onClick={() => history.push(`/Carrito`)}>
											Mi carrito
										</p>
									</div>
								</div>
							</>
						) :
						(
							<>
								<div className="flex my-5 ">

									<p className="mx-5 my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/Login`)}>Login</p>
									<p className="my-auto cursor-pointer lg:inline hidden titulos PM" style={{ borderBottomColor: "#ef4c00" }} onClick={() => dispatch(AskWaiterAction(menuItems, itemsConfiguracion, idMesaPedido, idCheckNum, idCheckSeq, rCenterPedido, !!openCheck))}>Pedir al Mesero</p>
									<p className="mx-5 my-auto cursor-pointer lg:inline hidden titulos PC" style={{ borderBottomColor: "#ef4c00" }} onClick={() => history.push(`/CheckUser`)}>Pedir la Cuenta</p>
									{/* <p className="my-auto cursor-pointer lg:inline hidden titulos CO" style={{ borderBottomColor: "#ef4c00" }}>Comentarios</p> */}
									<div className="ml-5 cursor-pointer border-r border-black pr-5 my-auto sm:inline hidden">
										<img src={perfil} alt="Imagen perfil" className="w-10 h-10" />
									</div>
									<div className="flex ml-2" >
										<div className="rounded-full flex items-center justify-center carrito my-auto" style={{ background: "#fcdbcc" }} onClick={() => history.push(`/Carrito`)}>
											<svg id="card_group" data-name="card group" xmlns="http://www.w3.org/2ef4c00/svg" className="svgCarrito cursor-pointer" width="15.137" height="12.969" viewBox="0 0 15.137 12.969">
												<path id="Trazado_1569" data-name="Trazado 1569" d="M33.754,101.958h2.63l-.462-.351c.077.233.153.466.23.7q.278.841.554,1.682.333,1.011.668,2.025.29.879.578,1.758c.094.284.184.57.281.853l0,.013a.479.479,0,1,0,.925-.254c-.077-.233-.153-.466-.23-.7q-.278-.841-.554-1.682-.333-1.013-.668-2.027-.29-.879-.578-1.758c-.094-.284-.182-.57-.281-.853l0-.013a.494.494,0,0,0-.462-.351h-2.63a.479.479,0,0,0,0,.958Z" transform="translate(-33.276 -101)" fill="#ef4c00" />
												<path id="Trazado_1570" data-name="Trazado 1570" d="M288.374,541.8q-.388.692-.778,1.383c-.037.067-.075.132-.112.2a.484.484,0,0,0,.414.72h7.315c.337,0,.676.006,1.014,0h.014a.479.479,0,0,0,0-.958h-7.315c-.337,0-.677-.01-1.014,0H287.9l.414.72q.388-.692.778-1.383c.037-.067.075-.133.112-.2a.479.479,0,1,0-.827-.482Z" transform="translate(-283.364 -534.53)" fill="#ef4c00" />
												<circle id="Elipse_1102" data-name="Elipse 1102" cx="1.088" cy="1.088" r="1.088" transform="translate(10.303 10.474)" fill="#ef4c00" />
												<path id="Trazado_1571" data-name="Trazado 1571" d="M658.4,738.381a1.43,1.43,0,0,0,.939,1.326,1.408,1.408,0,0,0,1.535-.409,1.407,1.407,0,1,0-2.474-.917.319.319,0,1,0,.639,0c0-.026,0-.053,0-.078a.24.24,0,0,1,0-.034c0-.064,0,.021,0,.019a.616.616,0,0,1,.038-.156c0-.013.014-.067.026-.07s-.029.059-.011.027c.006-.013.013-.026.017-.038a1.469,1.469,0,0,1,.081-.136c.03-.046-.043.05.008-.01.016-.018.032-.037.049-.054l.053-.051.024-.021c.011-.01.058-.032.016-.014s-.01.008,0,0l.035-.024c.018-.011.035-.022.054-.032s.049-.026.075-.038c.008,0,.042-.021,0,0s.016,0,.021-.006a.984.984,0,0,1,.134-.038l.043-.008c.035-.008-.03,0-.03,0,.029,0,.059-.006.088-.006a1.29,1.29,0,0,1,.157,0c.059,0-.061-.011.013,0,.029,0,.056.013.085.019s.048.014.072.022l.04.014s-.059-.03-.027-.011.094.048.139.077l.035.024c.011.008.035.014,0,0s-.011-.01,0,0l.032.027a1.429,1.429,0,0,1,.109.112c.048.053-.022-.037.008.01.013.021.027.04.04.061s.026.043.037.064l.014.029c.03.057.006,0,0-.008.019.006.04.121.046.142l.014.064c.013.061,0-.019,0-.021s0,.045,0,.054a1.089,1.089,0,0,1,0,.145.4.4,0,0,0,0,.045c0,.032-.019.027,0-.019a.486.486,0,0,0-.018.085c-.011.045-.029.088-.042.133s.029-.054.008-.018a.159.159,0,0,0-.013.029c-.013.026-.026.049-.04.073s-.026.042-.04.062c-.043.065.03-.032-.006.011a1.244,1.244,0,0,1-.109.113c-.006.006-.045.048-.056.048s.065-.045.014-.013l-.026.018c-.045.029-.091.053-.137.077s.057-.019-.011,0l-.07.024-.073.019-.032.006c-.07.016.051,0,.01,0a1.28,1.28,0,0,1-.157.006c-.025,0-.051,0-.078,0-.01,0-.048,0,0,0s-.011,0-.022,0a1.214,1.214,0,0,1-.164-.048c-.01,0-.042-.019,0,0l-.019-.01c-.026-.013-.049-.026-.074-.04s-.046-.03-.07-.046,0,.013.014.013c-.006,0-.027-.022-.032-.027a1.155,1.155,0,0,1-.118-.118.19.19,0,0,1-.027-.032c0,.006.038.054.013.014-.016-.024-.032-.046-.046-.07s-.03-.056-.045-.083c-.034-.066.016.051-.01-.021a1.18,1.18,0,0,1-.046-.166.1.1,0,0,0-.006-.032c.024.053,0,.045,0,.008s0-.067,0-.1a.323.323,0,0,0-.647,0Z" transform="translate(-648.415 -726.819)" fill="#ef4c00" />
												<circle id="Elipse_1103" data-name="Elipse 1103" cx="1.088" cy="1.088" r="1.088" transform="translate(4.798 10.474)" fill="#ef4c00" />
												<path id="Trazado_1572" data-name="Trazado 1572" d="M313.7,738.381a1.43,1.43,0,0,0,.939,1.326,1.408,1.408,0,0,0,1.535-.409,1.407,1.407,0,1,0-2.474-.917.319.319,0,1,0,.639,0c0-.026,0-.053,0-.078a.24.24,0,0,1,0-.034c0-.064,0,.021,0,.019a.618.618,0,0,1,.038-.156c0-.013.014-.067.026-.07s-.029.059-.011.027a.409.409,0,0,0,.018-.038,1.463,1.463,0,0,1,.081-.136c.03-.046-.043.05.008-.01.016-.018.032-.037.049-.054l.053-.051.024-.021c.011-.01.057-.032.016-.014s-.01.008,0,0l.035-.024c.018-.011.035-.022.054-.032s.049-.026.075-.038c.008,0,.042-.021,0,0s.016,0,.021-.006a.987.987,0,0,1,.134-.038l.043-.008c.035-.008-.03,0-.03,0,.029,0,.059-.006.088-.006a1.289,1.289,0,0,1,.157,0c.059,0-.061-.011.013,0,.029,0,.056.013.085.019s.048.014.072.022l.04.014s-.059-.03-.027-.011.094.048.139.077l.035.024c.011.008.035.014,0,0s-.011-.01,0,0l.032.027a1.433,1.433,0,0,1,.109.112c.048.053-.022-.037.008.01.013.021.027.04.04.061s.026.043.037.064l.014.029c.03.057.006,0,0-.008.019.006.04.121.046.142l.014.064c.013.061,0-.019,0-.021s0,.045,0,.054a1.1,1.1,0,0,1,0,.145.394.394,0,0,0,0,.045c0,.032-.019.027,0-.019a.5.5,0,0,0-.018.085c-.011.045-.029.088-.042.133s.029-.054.008-.018a.157.157,0,0,0-.013.029c-.013.026-.026.049-.04.073s-.026.042-.04.062c-.043.065.03-.032-.006.011a1.229,1.229,0,0,1-.109.113c-.006.006-.045.048-.056.048s.065-.045.014-.013l-.026.018c-.045.029-.091.053-.137.077s.057-.019-.011,0l-.07.024-.073.019-.032.006c-.07.016.051,0,.01,0a1.279,1.279,0,0,1-.157.006c-.026,0-.051,0-.078,0-.01,0-.048,0,0,0s-.011,0-.022,0a1.208,1.208,0,0,1-.164-.048c-.01,0-.042-.019,0,0l-.019-.01c-.026-.013-.05-.026-.073-.04s-.046-.03-.07-.046,0,.013.014.013c-.006,0-.027-.022-.032-.027a1.165,1.165,0,0,1-.118-.118.19.19,0,0,1-.027-.032c0,.006.038.054.013.014-.016-.024-.032-.046-.046-.07s-.03-.056-.045-.083c-.034-.066.016.051-.01-.021a1.168,1.168,0,0,1-.046-.166.1.1,0,0,0-.006-.032c.024.053,0,.045,0,.008s0-.067,0-.1a.323.323,0,0,0-.647,0Z" transform="translate(-309.218 -726.819)" fill="#ef4c00" />
												<g id="Grupo_3911" data-name="Grupo 3911" transform="translate(3.454 2.182)">
													<path id="Trazado_1573" data-name="Trazado 1573" d="M280.737,258l-1.939,5h-7.45l-1.648-5Z" transform="translate(-269.378 -257.674)" fill="#ef4c00" />
													<path id="Trazado_1574" data-name="Trazado 1574" d="M260.573,237.849q-.328.846-.655,1.691-.522,1.349-1.043,2.7l-.24.62.308-.235h-6.534c-.3,0-.6-.013-.906,0h-.013l.308.235q-.28-.85-.559-1.7-.443-1.344-.885-2.689c-.069-.206-.136-.412-.2-.62l-.308.4h10.59c.144,0,.288,0,.43,0h.019a.319.319,0,1,0,0-.639h-10.59c-.144,0-.287,0-.43,0h-.019a.322.322,0,0,0-.308.4q.28.85.559,1.7.443,1.344.885,2.689c.069.206.136.412.2.62a.33.33,0,0,0,.308.235h6.534c.3,0,.6.011.906,0h.013a.332.332,0,0,0,.308-.235q.328-.846.655-1.691.522-1.349,1.043-2.7l.24-.62a.32.32,0,0,0-.224-.393A.328.328,0,0,0,260.573,237.849Z" transform="translate(-249.522 -237.611)" fill="#ef4c00" />
												</g>
											</svg>
										</div>
										<p className="mx-5 my-auto cursor-pointer xl:inline hidden titulos" style={{ color: "#ef4c00" }} onClick={() => history.push(`/Carrito`)}>
											Mi carrito
										</p>
									</div>
								</div>
							</>
						)
				}
			</div>

			<div>
				{
					itemsImagenes != null ?
						(
							<>
								{
									itemsImagenes.length > 0 ?
										(
											<Swiper id="main">
												{itemsImagenes.map((_dish, index) => {
													return (
														<SwiperSlide key={`slide-${_dish.Id}`}>
															< ItemsBanner key={_dish.Id} name={_dish.Tipo} dish={_dish} />
														</SwiperSlide>
													)
												})}
											</Swiper>
										) : <div id="fondo" className="sm:border sm:rounded-lg m-0 sm:mx-5 mb-5 relative ">
											
											</div>
								}
							</>
						)
						: (
							<>
								<div id="fondo" className="sm:border sm:rounded-lg m-0 sm:mx-5 mb-5 relative ">
									
								</div>
							</>
						)
				}
			</div>

		</div>
	);
};

export default Header;
