import React, { useState, useLayoutEffect, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import { getUserLogin } from "../../redux/actions/loginActions";
import { useSelector, useDispatch } from "react-redux";

const Index = () => {

    const dispatch = useDispatch();
    const error = useSelector((state) => state.login.error);
    const LogC = useSelector((state) => state.login.currentUser);
    const history = useHistory();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState()

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            setUser(foundUser);
        }
    }, []);

    useEffect(() => {
        if (LogC != null) {
            history.push(`/`);
        }
    }, [LogC])


    const handlerClick = () => {
        let converPasswordBase64 = btoa(password);
        dispatch(getUserLogin(username, converPasswordBase64, history));
    };

    return (

        <div className="login">
            <div className="p-10 lg:w-2/6 md:w-2/5 sm:w-3/5 m-auto border border-gray-200 shadow-md">
                <div className="form-header text-center">
                    <h1>Inicio de Sesión</h1>
                </div>
                <div className="form-content">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" name="username" required="required" value={username} onChange={({ target }) => setUsername(target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">password</label>
                        <input type="password" id="password" name="password" required="required" value={password} onChange={({ target }) => setPassword(target.value)} />
                    </div>
                    <div className="form-group">
                        <button type="submit" onClick={handlerClick}>Iniciar Sesión</button>
                    </div>
                </div>
            </div>

        </div>


    );
};

export default Index;