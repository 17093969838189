import React from "react";
import { MetroSpinner } from "react-spinners-kit";
import { useHistory } from "react-router-dom";

const Redirect = () => {
	const history = useHistory();
	history.push("/");
	return (
		<div className="flex justify-center my-4">
			<h1 className="my-auto mr-5 font-bold text-2xl">
				Redireccionando...
			</h1>
			<div>
				<MetroSpinner color="#000" />
			</div>
		</div>
	);
};

export default Redirect;