import {
    DESCARGAR_QR,
    ACTUALIZAR_QR,
    AGREGAR_QR,
    ELIMINA_QR
} from "../types";

const initialState = {
    error: false,
    loading: false,
    itemsQr: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AGREGAR_QR:
            return {
                ...state,
                loading: false,
                error: false,
                itemsQr: [...state.itemsQr, action.payload]
            };
        case ACTUALIZAR_QR:
            return {
                ...state,
                loading: false,
                items: state.items.filter(
                    (item) => ((item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] && item.additions !== action.payload.additions) || (item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]))),
            };
        case DESCARGAR_QR:
            return {
                ...state,
                loading: false,
                error: false,
                itemsQr: action.payload
            };
        case ELIMINA_QR:
            return {
                ...state,
                loading: false,
                items: state.items.filter(
                    (item) => ((item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] && item.additions !== action.payload.additions) || (item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]))),
            };
        default:
            return state;
    }
}
