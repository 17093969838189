import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { configurationUpdateActions } from "../../redux/actions/configurationActions";
import Swal from "sweetalert2";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};


const ModalUpdateConfig = ({ openModal, setOpenModal, UrlServerUpdate, RevenueCenterUpdate, EmployeeObjectNumUpdate }) => {

    const dispatch = useDispatch();

    const [UrlServer, setUrlServerUpdate] = useState('');
    const [RevenueCenter, setRevenueCenterUpdate] = useState('');
    const [EmployeeObjectNum, setEmployeeObjectNumUpdate] = useState('');

    const handlerClick = () => {
        if (UrlServer == "" && UrlServerUpdate == "") {
            Swal.fire(
                '¡Alerta!',
                'El campo "Url Server" está vacío.',
                'warning'
            );
        } else if (RevenueCenter == "" && RevenueCenterUpdate == "") {
            Swal.fire(
                '¡Alerta!',
                'El campo "RevenueCenter" está vacío.',
                'warning'
            );
        } else if (EmployeeObjectNum == "" && EmployeeObjectNumUpdate == "") {
            Swal.fire(
                '¡Alerta!',
                'El campo "EmployeeObjectNum" está vacío.',
                'warning'
            );

        } else {
            dispatch(configurationUpdateActions(UrlServer.trim() == '' ? UrlServerUpdate : UrlServer, RevenueCenter.trim() == '' ? RevenueCenterUpdate : RevenueCenter, EmployeeObjectNum.trim() == '' ? EmployeeObjectNumUpdate : EmployeeObjectNum));
            setOpenModal(false);
        }

    };

    const limpiar = () => {
        setOpenModal(false);
        setUrlServerUpdate('');
        setRevenueCenterUpdate('');
        setEmployeeObjectNumUpdate('');
    }

    return (
        <div style={styles}>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                center={true}
                style
            >

                <div className="styleModal">
                    <h2 className="font-bold mb-5">Actualización Configuración</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <label className="self-center">Url Server:</label>
                        <input
                            name="UrlServer"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Url Server"
                            id="idUrlServer"
                            value={UrlServer == "" ? UrlServerUpdate : UrlServer}
                            onChange={event => setUrlServerUpdate(event.target.value)}
                        />
                        <label className="self-center">RevenueCenter: </label>
                        <input
                            name="RevenueCenter"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="RevenueCenter"
                            id="idRevenueCenter"
                            value={RevenueCenter == "" ? RevenueCenterUpdate : RevenueCenter}
                            onChange={event => setRevenueCenterUpdate(event.target.value)}
                        />
                        <label className="self-center">Employee Num: </label>
                        <input
                            name="EmployeeObjectNum"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="EmployeeObjectNum"
                            id="idEmployeeObjectNum"
                            value={EmployeeObjectNum == "" ? EmployeeObjectNumUpdate : EmployeeObjectNum}
                            onChange={event => setEmployeeObjectNumUpdate(event.target.value)}
                        />
                    </div>
                    <div className="flex justify-end">

                        <div className="flex">

                            <button
                                style={{ marginTop: "50px" }}
                                className={`py-1 text-red-600 focus:outline-none hover:text-red-400 px-4`}
                                onClick={(e) => limpiar()} >Cancelar
                            </button>

                            <button
                                style={{ marginTop: "50px" }}
                                className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                onClick={handlerClick} >Actualizar 
                                </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalUpdateConfig;