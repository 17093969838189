import React, { useState } from "react";
import { MetroSpinner } from "react-spinners-kit";
import { useSelector } from "react-redux";

const Tags = ({selected, setSelected, menuItemClass}) => {
	const cargando = useSelector((state) => state.menu.loading);

	let clss = "m-auto text-xs font-medium";
	let divTags = "rounded-full py-3 px-4 m-2 text-sm flex text-center cursor-pointer iconActive";
	let styleBg = { background: "#f4f9ff" };
	let styleOrange = { background: "#ef4c00"};

	const handleOptions = (text) => {
		setSelected(text)
	}

	if(menuItemClass){
		menuItemClass = menuItemClass.filter((itemClss)=> itemClss.clase !== "" )
	}

	return (
			<>
			{cargando ? (
					<>
						<div className="flex justify-center m-2">
							{" "}<MetroSpinner color="#000" />{" "}
						</div>
						<p className="text-center m-1">Cargando categorías...</p>
					</>
				) :	menuItemClass ? 
					<div className="flex m-5 justify-center flex-wrap">
						<div className={`${divTags}`} style={selected.name == "TODOS" ? styleOrange : styleBg} onClick={() => handleOptions({name:"TODOS", num:"TODOS"})}>
							<p className={`${clss} ${selected.name == "TODOS" ? 'text-white' : 'text-gray-700'}`}>TODOS</p>
						</div>
					{menuItemClass.map((item, i)=> {
						return(
							<div key={item.clase} className={`${divTags} `} style={selected.name == item.clase ? styleOrange : styleBg} onClick={() => handleOptions({name:item.clase, num:item.clase})}>
								<svg xmlns="http://www.w3.org/2000/svg" fill={`${selected.name == item.clase ? '#ef4c00' : 'none'}`} className="my-auto" width="27.003" height="23.386" viewBox="0 0 27.003 23.386">
									<g id="Grupo_842" data-name="Grupo 842" transform="translate(0.5 0.5)">
										<path id="Trazado_740" data-name="Trazado 740" d="M656.954,1042.955a7.914,7.914,0,1,0,0,11.192A7.914,7.914,0,0,0,656.954,1042.955Z" transform="translate(-640.167 -1037.359)" fill={`${selected.name == item.clase ? '#ef4c00' : 'none'}`} stroke={`${selected.name == item.clase ? 'white' : '#2e4765'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
										<path id="Trazado_741" data-name="Trazado 741" d="M671.223,1074.343a.384.384,0,0,0-.185.719,5.157,5.157,0,0,0,6.94-7.042.385.385,0,0,0-.718.2" transform="translate(-661.841 -1058.871)" fill={`${selected.name == item.clase ? '#ef4c00' : 'none'}`} stroke={`${selected.name == item.clase ? 'white' : '#2e4765'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
										<path id="Trazado_742" data-name="Trazado 742" d="M659.527,1050.962a5.758,5.758,0,0,0-5.758,5.758" transform="translate(-648.335 -1045.528)" fill={`${selected.name == item.clase ? 'none' : 'none'}`} stroke={`${selected.name == item.clase ? 'white' : '#2e4765'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
										<path id="Trazado_743" data-name="Trazado 743" d="M646.853,1028.219q-.2-.2-.4-.382a11.192,11.192,0,1,0-.967,17.373" transform="translate(-627.747 -1024.941)" fill={`${selected.name == item.clase ? 'none' : 'none'}`} stroke={`${selected.name == item.clase ? 'white' : '#2e4765'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
										<g id="Grupo_841" data-name="Grupo 841" transform="translate(20.596 2.197)">
											<path id="Trazado_744" data-name="Trazado 744" d="M728.27,1044a2.224,2.224,0,0,0-.724-1.629,4.086,4.086,0,0,1-1.169-2.983c0-1.98,1.21-3.92,2.7-3.92s2.7,1.939,2.7,3.92a4.093,4.093,0,0,1-1.171,2.982,2.219,2.219,0,0,0-.721,1.626v9.542a.812.812,0,0,1-.811.812h0a.811.811,0,0,1-.812-.812Z" transform="translate(-726.378 -1035.464)" fill={`${selected.name == item.clase ? '#ef4c00' : 'none'}`} stroke={`${selected.name == item.clase ? 'white' : '#2e4765'}`} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
											<path id="Trazado_745" data-name="Trazado 745" d="M739.326,1041.473c.663,0,1.505,1.255,1.505,2.665" transform="translate(-736.622 -1040.218)" fill={`${selected.name == item.clase ? '#ef4c00' : 'none'}`} stroke={`${selected.name == item.clase ? 'white' : '#2e4765'}`} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
										</g>
									</g>
								</svg>
								<p className={`${clss} ${selected.name === item.clase ? 'text-white' : 'text-gray-700'} ml-2`}>{item.clase}</p>
							</div>
						)
				})}
			</div>
				: <p className="flex justify-center m-2">No hay categorías, agregue una.</p>
			}
	</>
	);
};

export default Tags;
