import React, { useState, useLayoutEffect, useEffect } from "react";
import ItemQr from "./itemQr";
import ItemQrMob from "./itemQrMob";
import Header from "../Layout/Header";
import NavMob from "../Layout/NavMob";
import { useSelector, useDispatch } from "react-redux";
import { QrAction } from "../../redux/actions/qrActions";
import ModalAddQr from "../Layout/ModalQr";
import { MetroSpinner } from "react-spinners-kit";
import { setIdMesaAction } from "../../redux/actions/carritoActions";

const Qr = () => {

    const dispatch = useDispatch();

    const itemsQr = useSelector((state) => state.qrs.itemsQr);
    const idMesaPedido = useSelector((state) => state.carrito.idMesa);
    const [show, setShow] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const addItemHandler = () => {
        setOpenModal(true)
    }

    useEffect(() => {
        const cargarQr = () => dispatch(QrAction());
        if (!itemsQr)
            cargarQr();

        let parametros = window.location.search;
        let urlParams = new URLSearchParams(parametros);
        let paramIdMesa = urlParams.get('idMesa');
        let paramRCenter = urlParams.get('revenueCenter');

        let idMesaPOS = JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa
        let rCenterPOS = JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter
        const establecerIdMesa = () => dispatch(setIdMesaAction(idMesaPOS, rCenterPOS))

        if (idMesaPedido == null) {
            if(idMesaPOS) localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
            if(rCenterPOS) localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
            establecerIdMesa();
        }
        //eslint-disable-next-line
    }, [])


    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }
    const ShowWindowDimensions = () => {
        const [width] = useWindowSize();
        return width;
    }
    let newWidth = ShowWindowDimensions();
    let isMobile = newWidth < 640 ? true : false;
    let navMenu = newWidth < 1024 ? true : false;

    return (
        <div className="xl:w-4/5 m-auto relative" style={{ paddingBottom: isMobile ? "200px" : "0" }}>

            <NavMob show={show} setShow={setShow} navMenu={navMenu} />
            <Header setShow={setShow} />


            <div className="flex justify-between items-center">
                <h1 className="uppercase font-bold  m-5">Administración de QR'S</h1>
                <button className="px-3 rounded-md py-2 text-white bg-yellow-600 focus:outline-none hover:bg-yellow-500 mx-5" style={{ borderBottomColor: "#ef4c00" }} onClick={() => addItemHandler()} >Crear Qr</button>
            </div>


            {
                itemsQr == null ?
                    (<>
                        <div className="flex justify-center m-2">
                            {" "}<MetroSpinner color="#000" />{" "}
                        </div>
                        <p className="text-center m-1">Consultando QR'S.</p>
                    </>) : itemsQr.length > 0 ?
                        (<>

                            {itemsQr &&
                                <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 px-5">
                                    {itemsQr.map((_dish, index) => {
                                        return isMobile ? (
                                            <ItemQrMob key={index} name={_dish.Nombre} dish={_dish} />
                                        ) : (
                                            <ItemQr key={index} name={_dish.Nombre} dish={_dish} />
                                        );
                                    })}
                                </div>}
                        </>) : (
                            <div className="text-center m-3">
                                Aún no hay QR'S en el sistema, agregue uno.
                            </div>
                        )
            }
            <ModalAddQr openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}

export default Qr;