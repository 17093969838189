import {
    CONSULTAR_USER_LOGIN,
    ERROR_LOGIN

} from "../types";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";


export function getUserLogin(userName, Password, history) {


    return async (dispatch) => {
        try {

            let data = {
                admUserName: userName,
                admPassword: Password
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Users?item=GetUsers`,
                data,
                config
            );

            let aux = respuesta.data;

            let filterUser = aux.filter(itemLogin => itemLogin.UserName == userName);

            let userStoreLocal = {
                "Id": filterUser[0].Id,
                "Nombre": filterUser[0].Nombre,
                "Rol": filterUser[0].Role,
                "Url": filterUser[0].UrlFoto,
                "UserName": filterUser[0].UserName,
                "password": Password
            };
            localStorage.setItem("localUserObj", JSON.stringify(userStoreLocal));
            dispatch(descargarUsuario(filterUser));
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: '¡Ha ocurrido un error!',
                text: 'Usuario o contraseña erróneos.'
            });
        }
    };
}

const descargarUsuario = (itemsUsuariosLogin) => ({
    type: CONSULTAR_USER_LOGIN,
    payload: itemsUsuariosLogin
});


const errorLogin = () => ({
    type: ERROR_LOGIN,
    payload: true,
});

