import {
	AGREGAR_ITEM,
	ERROR_DESCARGA,
	ELIMINAR_ITEM,
	CONSULTAR_FAVORITO,
	ESTABLECER_ID_MESA,
	ESTABLECER_CAMBIOS_PEDIDOS,
	ESTABLECER_CAMBIOS_CARRITOPEDIDO,
	PLATO_SELECCIONADO,
	REQUERIMIENTO_SELECCIONADO,
	CONT_CART,
	AGREGAR_ITEMS
} from "../types";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";

var parseString = require("xml2js").parseString;

export function carritoAddActions(item) {
	return async (dispatch) => {
		try {
			dispatch(agregarItem(item));
			Swal.fire({
				title: "Correcto",
				text: "El item se ha agregado al carrito.",
				icon: "success",
				confirmButtonText: "Ok.",
			});
		} catch (error) {
			dispatch(errorDescarga());
		}
	};
}
export function carritoAddsActions(item) {
	return async (dispatch) => {
		try {
			dispatch(agregarItems(item));
		} catch (error) {
			dispatch(errorDescarga());
		}
	};
}
export function setIdMesaAction(idMesa, rCenter) {
	return async (dispatch) => {
		try {
			dispatch(setIdDelPedido(idMesa, rCenter));
		} catch (e) {}
	};
}

export function dishesGetFavoritesActions(admUserNames, admPasswords) {
	return async (dispatch) => {
		try {
			let data = {
				admUserName: admUserNames,
				admPassword: admPasswords,
			};
			let config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const respuesta = await clienteAxios.put(
				`/Config?item=GetFavorites`,
				data,
				config
			);
			dispatch(getFavoritesActios(respuesta.data));
		} catch (e) {}
	};
}

export function AddToExistingCheck(
	objetosCarritoNew,
	xmlCarritosElementos,
	idMesa,
	itemsConfiguracion,
	idCheckNum,
	idCheckSeq,
	revenueCenter
) {
	return async (dispatch) => {
		try {
			let data = {
				urlServer: itemsConfiguracion.UrlServer,
				revenueCenter: revenueCenter || itemsConfiguracion.RevenueCenter,
				employeeObjectNum: itemsConfiguracion.EmployeeObjectNum,
				numMesa: idMesa,
				tMedia: "21",
				checkNum: idCheckNum,
				checkSeq: idCheckSeq,
				menuItems: xmlCarritosElementos,
			};

			let config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const respuesta = await clienteAxios.put(
				`/MenuItem?item=AddToExistingCheck`,
				data,
				config
			);

			dispatch(establecerUltimoCarrito(objetosCarritoNew));

			Swal.fire({
				title: "Correcto",
				text: "Se ha actualizado la compra de los productos.",
				icon: "success",
				confirmButtonText: "Ok.",
			});
		} catch (e) {}
	};
}

export function PostTransactionAction(
	objetosCarrito,
	xmlCarritosElementos,
	idMesa,
	itemsConfiguracion,
	revenueCenter
) {
	return async (dispatch) => {
		try {
			let data = {
				urlServer: itemsConfiguracion.UrlServer,
				revenueCenter: revenueCenter || itemsConfiguracion.RevenueCenter,
				employeeObjectNum: itemsConfiguracion.EmployeeObjectNum,
				numMesa: idMesa,
				tMedia: "21",
				menuItems: xmlCarritosElementos,
			};

			let config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const respuesta = await clienteAxios.put(
				`/MenuItem?item=PostTransaction`,
				data,
				config
			);
			let CheckSeq =
				respuesta.data["soap:Envelope"]["soap:Body"].PostTransactionExResponse
					.pGuestCheck.CheckSeq;
			let CheckNum =
				respuesta.data["soap:Envelope"]["soap:Body"].PostTransactionExResponse
					.pGuestCheck.CheckNum;

			dispatch(setIdCambioPedido(CheckSeq, CheckNum));
			dispatch(establecerUltimoCarrito(objetosCarrito));

			Swal.fire({
				title: "Correcto",
				text: "Se ha generado la compra de los productos.",
				icon: "success",
				confirmButtonText: "Ok.",
			});
		} catch (e) {
		console.log(e)
		}
	};
}

export async  function  getCheckDetail(
	itemsConfiguracion,
	checkNum,
	checkSeq
) {
	try {
		let data = {
			urlServer: itemsConfiguracion.UrlServer,
			checkNum,
			checkSeq
		};


		let config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const respuesta = await clienteAxios.post(
			`/MenuItem?item=GetCheckDetail`,
			data,
			config
		);
		
		let res = respuesta["data"]["soap:Envelope"]["soap:Body"]["GetCheckDetailResponse"]["ppCheckDetailResponse"]["CheckDetail"]
		let itemCheck = ''
			
		parseString(res, function (err, asd) {
			itemCheck = asd["Check"]["DetailLines"][0]["DetailLine"]
		});

		let items = []
		itemCheck.forEach(x => { 
			if(!!x.MenuItem) return items.push(x.MenuItem[0])
		})

		return items
	} catch (e) {
		console.log(e)
		return []
	}
}

export function dishesAddFavoritesActions(
	userNameDish,
	passworUserDish,
	idDish
) {
	return async (dispatch) => {
		try {
			let data = {
				admUserName: userNameDish,
				admPassword: passworUserDish,
				menuitemDefID: idDish[0],
			};
			let config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const respuesta = await clienteAxios.put(
				`/Config?item=AddFavorite`,
				data,
				config
			);

			dispatch(dishesGetFavoritesActions(userNameDish, passworUserDish));
		} catch (e) {}
	};
}

export function setDishSelectedAction(dish) {
	return async (dispatch) => {
		try {
			dispatch(setDishSelected(dish));
		} catch (e) {
			console.log(e);
			dispatch(errorDescarga());
		}
	};
}

export function setRequirementSelectedAction(req) {
	return async (dispatch) => {
		try {
			dispatch(setRequirementSelected(req));
		} catch (e) {
			console.log(e);
			dispatch(errorDescarga());
		}
	};
}

export function carritoDeleteActions(item) {
	return async (dispatch) => {
		try {
			dispatch(eliminarItem(item));
		} catch (error) {
			dispatch(errorDescarga());
		}
	};
}
export function contCartActions(item) {
	return async (dispatch) => {
		try {
			dispatch(contCart(item));
		} catch (error) {
			dispatch(errorDescarga());
		}
	};
}
const setRequirementSelected = (req) => ({
	type: REQUERIMIENTO_SELECCIONADO,
	payload: req,
});

const setDishSelected = (dish) => ({
	type: PLATO_SELECCIONADO,
	payload: dish,
});
const contCart  = (dish) => ({
	type: CONT_CART,
	payload: dish,
});
const setIdDelPedido = (idMesa, rCenter) => ({
	type: ESTABLECER_ID_MESA,
	payload: {idMesa, rCenter},
});
const setIdCambioPedido = (idCheckSeq, idCheckNum) => ({
	type: ESTABLECER_CAMBIOS_PEDIDOS,
	payload: idCheckSeq,
	idCheckNum,
});

const agregarItem = (item) => ({
	type: AGREGAR_ITEM,
	payload: item,
});
const agregarItems = (item) => ({
	type: AGREGAR_ITEMS,
	payload: item,
});
const eliminarItem = (item) => ({
	type: ELIMINAR_ITEM,
	payload: item,
});

const errorDescarga = () => ({
	type: ERROR_DESCARGA,
	payload: true,
});

const getFavoritesActios = (itemsAdd) => ({
	type: CONSULTAR_FAVORITO,
	payload: itemsAdd,
});

const establecerUltimoCarrito = (itemsAddCarrito) => ({
	type: ESTABLECER_CAMBIOS_CARRITOPEDIDO,
	payload: itemsAddCarrito,
});
