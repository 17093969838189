import React, { useEffect } from "react";
import { BtnPag } from "../components/Layout/StyleComp";
const Paginacion = ({ currentPage, postsPerPage, totalPosts, paginate }) => {
	const pageNumbers = [];
	const maxPage = Math.ceil(totalPosts / postsPerPage);

	for (let i = 1; i <= maxPage; i++) {
		pageNumbers.push(i);
	}

	useEffect(() => {
		paginate(1) //Se devuelve a la pag 1 automaticamente
		//eslint-disable-next-line
	}, [totalPosts])

	let style = "my-3 mr-1 px-3 py-1 border rounded-md focus:outline-none";

	return (
		<>
			<nav>
				<ul className="flex justify-center">
					{currentPage !== 1 && (
						<>
							<button
								onClick={() => paginate(currentPage - 1)}
								className="my-3 mr-1 focus:outline-none hover:text-gray-700"
							>
								Anterior
							</button>

							<BtnPag
								onClick={() => paginate(currentPage - 1)}
								className={style}
							>
								{currentPage - 1}
							</BtnPag>
						</>
					)}
					<button
						onClick={() => paginate(currentPage)}
						className={style + " text-white "}
            style={{background: "#ef4c00"}}
					>
						{currentPage}
					</button>
					{currentPage !== maxPage && (
						<>
							<BtnPag
								onClick={() => paginate(currentPage + 1)}
								className={style}
							>
								{currentPage + 1}
							</BtnPag>

							<button
								onClick={() => paginate(currentPage + 1)}
								className="my-3  focus:outline-none hover:text-gray-700"
							>
								Siguiente
							</button>
						</>
					)}
				</ul>
			</nav>
		</>
	);
};

export default Paginacion;
