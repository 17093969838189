import { combineReducers } from "redux";
import menuReducer from "./menuReducer";
import carritoReducer from "./carritoReducer";
import usuarioReducer from "./usuarioReducer";
import loginReducer from "./loginReducer";
import imagenReducer from "./imagenReducer";
import qrReducer from "./qrReducer";
import configurationReducer from "./configurationReducer"

export default combineReducers({
	carrito: carritoReducer,
	menu: menuReducer,
	usuario: usuarioReducer,
	login: loginReducer,
	imagenes: imagenReducer,
	qrs: qrReducer,
	configurationsR: configurationReducer
});
