// MENÚ

export const DESCARGA_MENU_ITEMS = "DESCARGA_MENU_ITEMS";
export const ERROR_DESCARGA = "ERROR_DESCARGA";
export const CUENTA_MENU = "CUENTA_MENU";
export const COMENZAR_DESCARGA_ITEMS = "COMENZAR_DESCARGA_ITEMS";

// CARRITO

export const PLATO_SELECCIONADO = "PLATO_SELECCIONADO";
export const REQUERIMIENTO_SELECCIONADO = "REQUERIMIENTO_SELECCIONADO";
export const AGREGAR_ITEM = "AGREGAR_ITEM";
export const AGREGAR_ITEMS = "AGREGAR_ITEMS";
export const ELIMINAR_ITEM = "ELIMINAR_ITEM";
export const CONSULTAR_FAVORITO = "CONSULTAR_FAVORITO";
export const ESTABLECER_ID_MESA = "ESTABLECER_ID_MESA";
export const ESTABLECER_CAMBIOS_PEDIDOS = "ESTABLECER_CAMBIOS_PEDIDOS";
export const CONT_CART = "CONT_CART";


//tiene la finalidad de almacenar el ultimo pedido generado
// con el fin de que cuando se realice otro pedido solo agregue los nuevo elementos
export const ESTABLECER_CAMBIOS_CARRITOPEDIDO =
	"ESTABLECER_CAMBIOS_CARRITOPEDIDO";

//USUARIOS

export const DESCARGAR_USUARIO = "DESCARGAR_USUARIO";
export const AGREGAR_USUARIO = "AGREGAR_USUARIO";
export const ELIMINA_USUARIO = "ELIMINA_USUARIO";
export const ACTUALIZAR_USUARIO = "ACTUALIZAR_USUARIO";

//LOGIN

export const CONSULTAR_USER_LOGIN = "CONSULTAR_USER_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";

//IMAGENES

export const DESCARGAR_IMAGENES = "DESCARGAR_IMAGENES";
export const ACTUALIZAR_IMAGENES = "ACTUALIZAR_IMAGENES";
export const AGREGAR_IMAGENES = "AGREGAR_IMAGENES";
export const ELIMINA_IMAGENES = "ELIMINA_IMAGENES";
export const DESCARGAR_IMAGENES_BANNER = "DESCARGAR_IMAGENES_BANNER";
export const DESCARGAR_IMAGENES_LOGO = "DESCARGAR_IMAGENES_LOGO";

//QRS

export const DESCARGAR_QR = "DESCARGAR_QR";
export const AGREGAR_QR = "AGREGAR_QR";
export const ELIMINA_QR = "ELIMINA_QR";
export const ACTUALIZAR_QR = "ACTUALIZAR_QR";

//CONFIGURATION

export const DESCARGAR_CONFIGUTATION = "DESCARGAR_CONFIGUTATION";
export const ACTUALIZAR_CONFIGURATION = "ACTUALIZAR_CONFIGURATION";
export const DESCARGAR_OPEN_CHECKS = "DESCARGAR_OPEN_CHECKS";
export const DESCARGAR_CHECK_USER = "DESCARGAR_CHECK_USER";


