import React, { useState, useLayoutEffect, useEffect } from "react";
import Header from "../Layout/Header";
import NavMob from "../Layout/NavMob";
import { useSelector, useDispatch } from "react-redux";
import { ImagenesAction } from "../../redux/actions/imagenesActions";
import ItemImagenes from "./ItemImagenes";
import { MetroSpinner } from "react-spinners-kit";
import ModalImage from "../Layout/ModalImage.js";
import { setIdMesaAction } from "../../redux/actions/carritoActions";
import { imagenDeleteActions } from "../../redux/actions/imagenesActions";
import Swal from "sweetalert2";
import Paginacion from "../../utils/Paginacion";
import Imagen from "./Imagen";

const Imagenes = () => {


    const dispatch = useDispatch();
    const itemsImagenes = useSelector((state) => state.imagenes.itemsImagenes);
    const idMesaPedido = useSelector((state) => state.carrito.idMesa);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const cargaImagenes = () => dispatch(ImagenesAction());
        if (!itemsImagenes)
            cargaImagenes();

        let parametros = window.location.search;
        let urlParams = new URLSearchParams(parametros);
        let paramIdMesa = urlParams.get('idMesa');
        let paramRCenter = urlParams.get('revenueCenter');

        let idMesaPOS = JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa
        let rCenterPOS = JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter
        const establecerIdMesa = () => dispatch(setIdMesaAction(idMesaPOS, rCenterPOS))

        if (idMesaPedido == null) {
            if(idMesaPOS) localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
            if(rCenterPOS) localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
            establecerIdMesa();
        }
        //eslint-disable-next-line
    }, []);

    const EliminarImagen = (idImagenEliminada) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Se eliminará la imagen con el Id: " + idImagenEliminada + "  del sistema.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                dispatch(imagenDeleteActions(idImagenEliminada));
            }
        });
    };


    const [show, setShow] = useState(false);
    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }

    const ShowWindowDimensions = () => {
        const [width] = useWindowSize();
        return width;
    }

    const addItemHandler = () => {
        setOpenModal(true)
    }

    let newWidth = ShowWindowDimensions();
    let isMobile = newWidth < 640 ? true : false;
    let navMenu = newWidth < 1024 ? true : false;

    // Paginacion
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = itemsImagenes ? itemsImagenes.slice(indexOfFirstPost, indexOfLastPost) : []

	// Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
    let thClass = 'border border-black text-black px-4 py-2'

    return (
        <>
            <div className=" xl:w-4/5 m-auto relative" style={{ paddingBottom: isMobile ? "200px" : "0" }}>

                <NavMob show={show} setShow={setShow} navMenu={navMenu} />
                <Header setShow={setShow} />

                <div className="flex justify-between items-center">
                    <h1 className="uppercase font-bold m-5">Administración de Imagenes</h1>
                    <button className="px-3 rounded-md py-2 text-white bg-yellow-600 focus:outline-none hover:bg-yellow-500 mx-5" onClick={() => addItemHandler()} >Añadir</button>
                </div>

                {
                    itemsImagenes == null ?
                        (<>
                            <div className="flex justify-center m-2">
                                {" "}<MetroSpinner color="#000" />{" "}
                            </div>
                            <p className="text-center m-1">Consultando Imagenes.</p>
                        </>) : itemsImagenes.length > 0 ?
                            (<>

                                {itemsImagenes &&
                                    <>
                                        <div style={{ overflowX: "auto"}} className="m-3">
                                            <table className="text-center w-full border ">
                                                <thead>
                                                    <tr >
                                                        <th className={thClass}>Id</th>
                                                        <th className={thClass}>Url</th>
                                                        <th className={thClass}>Tipo</th>
                                                        <th className={thClass}>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentPosts.map((image) => {
                                                            return (
                                                                <Imagen
                                                                    key={image.Id}
                                                                    img={image}
                                                                    eliminarImagen={EliminarImagen}
                                                                />
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Paginacion
                                            postsPerPage={postsPerPage}
                                            totalPosts={itemsImagenes.length}
                                            paginate={paginate}
                                            currentPage={currentPage}
                                        />
                                    </>
                                }
                            </>) : (
                                <div className="text-center m-3">
                                    Aún no hay Imagenes en el sistema.
                                </div>
                            )
                }
                <ModalImage openModal={openModal} setOpenModal={setOpenModal} />
            </div>
        </>
    );
};

export default Imagenes;
