import {
    CONSULTAR_USER_LOGIN,
    ERROR_LOGIN,

} from "../types";

const initialState = {
    error: false,
    loading: false,
    itemsUsuariosLogin: null,
    currentUser: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CONSULTAR_USER_LOGIN:
            return {
                ...state,
                loading: false,
                error: false,
                currentUser: action.payload
            };
        case ERROR_LOGIN:
            return {
                ...state,
                loading: false,
                error: true,
                itemsUsuariosLogin: null
            };
        default:
            return state;
    }
}
