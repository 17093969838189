import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useSelector } from "react-redux";
import Select from "react-select";
import { setRequirementSelectedAction, setDishSelectedAction } from "../../redux/actions/carritoActions.js";
import { useDispatch } from "react-redux";

const styles = {
	fontFamily: "sans-serif",
	textAlign: "center",
};

const ModalAdd = ({ openModal, setOpenModal, handleSave }) => {
	const dispatch = useDispatch();

	const originalClass = useSelector((state) => state.menu.originalClass);
	const platoSeleccionado = useSelector((state) => state.carrito.platoSeleccionado);
	const menuItemDefinitions = useSelector((state) => state.menu.menuItemDefinitions);	

	const [menuItemsOptions, setMenuItemsOptions] = useState(null);
	const [select, setSelect] = useState(null);
	const [Requirements, setRequirements] = useState([]);

	useEffect(() => {
		const cargarAdiciones = () => {
			let options = []
			let jsonCMember = [];		
			let itemClass = originalClass.filter(itmC => itmC.ObjectNumber[0] == platoSeleccionado.MenuItemClassObjNum[0] )
			if(Array.isArray(itemClass) && itemClass.length){
				itemClass[0].RequiredCondiments[0].split("").forEach((item,idx) => { 
					if(item == "1"){
						jsonCMember.push(originalClass.filter(_class => {
								if (_class.MemberOfCondiments[0].split("")[idx] == item) return _class;
							}) 
						);
					}
				});

				if(Array.isArray(jsonCMember) && jsonCMember.length){
					let Requerimientos = menuItemDefinitions.filter(itemMenu => itemMenu.MenuItemClassObjNum[0] == jsonCMember[0][0].ObjectNumber[0] );
					if(Array.isArray(Requerimientos) && Requerimientos.length){
						Requerimientos.map(req=>{
							req.PLatoId = platoSeleccionado.MenuItemDefID[0]
							options.push({
								value: req.Name1[0].StringText[0],
								label: req.Name1[0].StringText[0],
							});
						})
						setMenuItemsOptions(options);
						setRequirements(Requerimientos)
						
					}else setRequirements(null)
				}else setRequirements(null)
			}else setRequirements(null)
		}

		if (menuItemDefinitions && platoSeleccionado && originalClass) cargarAdiciones();
		//eslint-disable-next-line
	}, [platoSeleccionado]);

	useEffect(() => {
		if(!Requirements){
			handleSave(null);
			setOpenModal(false);
		}
		//eslint-disable-next-line
	}, [Requirements]);

	const handlerClick = () => {
		let add = [];
		if (select) select.map((sel) => add.push(sel.value));
		handleSave(add);
		let requerimientos = []

		Requirements.map(req=>{
			add.map(adicion=>{
				if(req.Name1[0].StringText[0] == adicion) requerimientos.push(req)
			})
		})
		dispatch(setRequirementSelectedAction(requerimientos));
		dispatch(setDishSelectedAction(null));
		setOpenModal(false);
	};

	return (
		<div style={styles}>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				center={true}
				style
			>
				<div className="styleModal">
					<h2 className="font-bold mb-5">Requerimientos</h2>
					{menuItemsOptions && (
						<>
							<Select
								// defaultMenuIsOpen
								menuIsOpen
								options={menuItemsOptions}
								isMulti
								name="dishes"
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={(value) => setSelect(value)}
							/>
							<div className="flex justify-end">
								<div className="flex">
									<button
										style={{ marginTop: "320px" }}
										className={`py-1 text-red-600 focus:outline-none hover:text-red-400 px-4`}
										onClick={() => setOpenModal(false)}
									>
										Cancelar
									</button>
									<button
										style={{ marginTop: "320px" }}
										className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
										onClick={handlerClick}
									>
										Guardar
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default ModalAdd;
