import styled from 'styled-components';

export const BtnHelpers = styled.button `
	border: 1px solid #ef4c00;
	outline: none;
	:hover {
		background: #ef4c00;
	color: #fff;
	}
`

export const BtnPag = styled.button `
	outline: none;
	:hover {
		border: 1px solid #ef4c00;
	}
`