import React, { useState } from "react";
import Imagen from "../../recursos/LogoFinal.png";
import { useDispatch } from "react-redux";
import { usuarioDeleteActions } from "../../redux/actions/usuariosActions.js";
import ModalUpdateUser from "../Layout/ModalUserUpdate";

import Swal from "sweetalert2";

const ItemUsuario = ({ dish }) => {


    const dispatch = useDispatch();
    const [openModalUpdate, setOpenModalUpdate] = useState(false);

    const [idUserUpdate, setIdUserUpdate] = useState('');
    const [nameUserUpdate, setNameUserUpdate] = useState('');
    const [userNameUpdate, setUserNameUpdate] = useState('');
    const [rolUserUpdate, setRolUserUpdate] = useState('');

    const EliminarUsuario = (idUsuarioEliminado) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Se eliminará el usuario con el Id: " + idUsuarioEliminado + "  del sistema.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                dispatch(usuarioDeleteActions(idUsuarioEliminado));
            }
        });
    };

    const ActualizarUsuario = (id, Nombre, Role, UserName) => {
        setIdUserUpdate(id);
        setNameUserUpdate(Nombre);
        setUserNameUpdate(UserName);
        setRolUserUpdate(Role);
        setOpenModalUpdate(true);
    }

    return (
        <div className="border asd rounded-lg w-full mb-5">
            <div className="rounded-lg relative" >
                <img src={dish.UrlFoto == null || dish.UrlFoto == "" ? Imagen : dish.UrlFoto} 
                    alt="dish" className="rounded-t-md w-full" 
                    style={{maxHeight: "200px", minHeight: "200px"}} 
                    onError={(e)=>{e.target.onerror = null; e.target.src=Imagen}} 
                />
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between">
                    <h1 className="titulos font-medium my-auto">Identificación: </h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.Id == null ? "..." : dish.Id}</p>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between">
                    <h1 className="titulos font-medium my-auto">Nombre: </h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.Nombre == null ? "..." : dish.Nombre}</p>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between">
                    <h1 className="titulos font-medium my-auto">Rol: </h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.Role == null ? "..." : dish.Role}</p>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <div className="flex mb-2 justify-between">
                    <h1 className="titulos font-medium my-auto">Nombre de Usuario: </h1>
                    <div className="flex flex-wrap justify-center">
                        <p className="parrafos my-auto">{dish.UserName == null ? "..." : dish.UserName}</p>
                    </div>
                </div>
            </div>

            <div className="flex mb-2 justify-end gap-4 m-3">
                <svg onClick={() => ActualizarUsuario(dish.Id, dish.Nombre, dish.Role, dish.UserName)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0d6efd" className="cursor-pointer bi bi-pencil-fill" viewBox="0 0 16 16">
                    <title>Editar</title>
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                </svg>
                <svg onClick={() => EliminarUsuario(dish.Id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" className="cursor-pointer bi bi-trash-fill" viewBox="0 0 16 16">
                    <title>Eliminar</title>
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                </svg>
            </div>
            <ModalUpdateUser openModal={openModalUpdate} setOpenModal={setOpenModalUpdate} IdUser={idUserUpdate} UserName={userNameUpdate} Rol={rolUserUpdate} Nombre={nameUserUpdate} />
        </div>
    )
};

export default ItemUsuario;
