import React, { useState, useLayoutEffect, useEffect } from "react";
import ItemUsuario from "./itemUsuarios";
import ItemUsuarioMob from "./itemUsuariosMob";
import Header from "../Layout/Header";
import NavMob from "../Layout/NavMob";
import { useSelector, useDispatch } from "react-redux";
import { UsuarioAction } from "../../redux/actions/usuariosActions";
import ModalAddUser from "../Layout/ModalUser";
import { MetroSpinner } from "react-spinners-kit";
import { setIdMesaAction } from "../../redux/actions/carritoActions";

const Usuarios = () => {

    const dispatch = useDispatch();

    const itemsUsuarios = useSelector((state) => state.usuario.itemsUsuarios);
    const idMesaPedido = useSelector((state) => state.carrito.idMesa);
    const [show, setShow] = useState(false);
    const [openModal, setOpenModal] = useState(false);


    const addItemHandler = () => {
        setOpenModal(true)
    }

    useEffect(() => {
        const cargaUsuarios = () => dispatch(UsuarioAction());
        if (!itemsUsuarios)
            cargaUsuarios();

        let parametros = window.location.search;
        let urlParams = new URLSearchParams(parametros);
        let paramIdMesa = urlParams.get('idMesa');
        let paramRCenter = urlParams.get('revenueCenter');

        let idMesaPOS = JSON.parse(localStorage.getItem("POSIdMesa")) || paramIdMesa
        let rCenterPOS = JSON.parse(localStorage.getItem("POSRCenter")) || paramRCenter
        const establecerIdMesa = () => dispatch(setIdMesaAction(idMesaPOS, rCenterPOS))

        if (idMesaPedido == null) {
            if(idMesaPOS) localStorage.setItem("POSIdMesa", JSON.stringify(idMesaPOS));
            if(rCenterPOS) localStorage.setItem("POSRCenter", JSON.stringify(rCenterPOS));
            establecerIdMesa();
        }
        //eslint-disable-next-line
    }, [])

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }

    const ShowWindowDimensions = () => {
        const [width] = useWindowSize();
        return width;
    }

    let newWidth = ShowWindowDimensions();
    let isMobile = newWidth < 640 ? true : false;
    let navMenu = newWidth < 1024 ? true : false;

    return (

        <>
            <div className=" xl:w-4/5 m-auto relative" style={{ paddingBottom: isMobile ? "200px" : "0" }}>

                <NavMob show={show} setShow={setShow} navMenu={navMenu} />
                <Header setShow={setShow} />


                <div className="flex justify-between items-center">
                    <h1 className="uppercase font-bold  m-5">Administración de Usuarios</h1>
                    <button className="px-3 rounded-md py-2 text-white bg-yellow-600 focus:outline-none hover:bg-yellow-500 mx-5" onClick={() => addItemHandler()} >Añadir</button>
                </div>


                {
                    itemsUsuarios == null ?
                        (<>
                            <div className="flex justify-center m-2">
                                {" "}<MetroSpinner color="#000" />{" "}
                            </div>
                            <p className="text-center m-1">Consultando Usuarios.</p>
                        </>) : itemsUsuarios.length > 0 ?
                            (<>

                                {itemsUsuarios && <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 px-5">
                                    {itemsUsuarios.map((_dish, index) => {
                                        return isMobile ? (
                                            <ItemUsuarioMob key={index} name={_dish.Nombre} dish={_dish} />
                                        ) : (
                                            <ItemUsuario key={index} name={_dish.Nombre} dish={_dish} />
                                        );
                                    })}
                                </div>}
                            </>) : (
                                <div className="text-center m-3">
                                    Aún no hay Usuarios en el sistema, agregue uno.
                                </div>
                            )
                }
                <ModalAddUser openModal={openModal} setOpenModal={setOpenModal} />
            </div>
        </>
    );

};

export default Usuarios;
