import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { usuarioUpdateActions } from "../../redux/actions/usuariosActions";
import axios from "axios";
import { MetroSpinner } from "react-spinners-kit";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};


const ModalUpdateUser = ({ openModal, setOpenModal, IdUser, UserName, Rol, Nombre }) => {

    const dispatch = useDispatch();

    const [nameUserUpd, setNameUserUpd] = useState('')
    const [userNameUpd, setUserNameUpd] = useState('')
    const [roleUpd, setRoleUpd] = useState('')
    const [passwdUpd, setPasswdUpd] = useState('')

    const [image, setImage] = useState(null);
    const [cargando, setCargando] = useState(false);

    let userStorage = localStorage.getItem("localUserObj");
	let userInfoStorage = JSON.parse(userStorage);

    const cloud_name = "duwwaqmaw";
    const upload_preset = "y5wb8b85";
    const UploadImagenUpdate = () => {
        setCargando(true);
        const { files } = document.querySelector(".app_uploadInput");
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("upload_preset", upload_preset);
        const options = {
            method: "POST",
            body: formData,
        };
        return fetch(`https://api.Cloudinary.com/v1_1/${cloud_name}/image/upload`, options)
            .then((res) => res.json())
            .then((res) => {
                let auxUrlImage = res.url;
                setImage(auxUrlImage)
                setCargando(false);
            })
            .catch((err) => console.log(err));
    };


    const handlerClick = () => {
        
        let converPasswordBase64 = btoa(passwdUpd);
        dispatch(usuarioUpdateActions(IdUser, converPasswordBase64, roleUpd.trim() == '' ? Rol : roleUpd, nameUserUpd.trim() == '' ? Nombre : nameUserUpd, image, userInfoStorage));
        setOpenModal(false);

    };

    const limpiar = () => {

        setOpenModal(false);
        setNameUserUpd('');
        setUserNameUpd('');
        setRoleUpd('');

    }

    return (
        <div style={styles}>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                center={true}
                style
            >
                <div className="styleModal">
                    <h2 className="font-bold mb-5">Actualización De Usuario</h2>

                    <div className="grid grid-cols-2 gap-2">

                        <label className="self-center">User Name:</label>
                        <input
                            name="userName"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="User Name"
                            id="idUserName"
                            value={userNameUpd == "" ? UserName : userNameUpd}
                            disabled
                            onChange={event => setUserNameUpd(event.target.value)}
                        />

                        <label className="self-center">Rol:</label>
                        <select
                            name="Rol"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Rol"
                            id="idRole"
                            value={roleUpd == "" ? Rol : roleUpd}
                            onChange={event => setRoleUpd(event.target.value)}
                        >
                            <option value="">Seleccionar rol</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Cliente">Cliente</option>
                        </select>
                        <label className="self-center">Nombre:</label>
                        <input
                            name="Nombre"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Nombre Completo"
                            id="idNombre"
                            value={nameUserUpd.trim() == '' ? Nombre : nameUserUpd}
                            onChange={event => setNameUserUpd(event.target.value)}
                        />
                        <label className="self-center">Contraseña:</label>
                        <input
                            name="contrase;a"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Contraseña"
                            id="idContraseña"
                            value={passwdUpd}
                            onChange={event => setPasswdUpd(event.target.value)}
                        />
                    </div>

                    <div className="flex flex-wrap mt-3 items-center justify-between">
                        <input type="file" className="app_uploadInput" />
                        <button className="app_uploadButton my-2 px-3 rounded-md py-2 text-white bg-green-600 focus:outline-none hover:bg-green-500" onClick={UploadImagenUpdate}>Subir imagen</button>
                    </div>
   
                        {cargando == true ? (
                            <>
                                <div className="flex justify-center m-2">
                                    {" "}<MetroSpinner color="#000" />{" "}
                                </div>
                                <p className="text-center m-1">Subiendo Imagen.</p>
                            </>
                        ) : null
                        }

                    <div className="flex justify-end">

                        <div className="flex">
                            <button
                                style={{ marginTop: "50px" }}
                                className={`py-1 text-red-600 focus:outline-none hover:text-red-400 px-4`}
                                onClick={(e) => limpiar()} >Cancelar
                            </button>
                            {cargando == true ? (
                                <>
                                    <button
                                        disabled
                                        style={{ marginTop: "50px" }}
                                        className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                        onClick={handlerClick} >Espera...
                                </button>
                                </>
                            ) : <>
                                <button
                                    style={{ marginTop: "50px" }}
                                    className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                    onClick={handlerClick} >Actualizar Información
                                </button>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalUpdateUser;