import {
	DESCARGA_MENU_ITEMS,
	ERROR_DESCARGA,
	CUENTA_MENU,
	COMENZAR_DESCARGA_ITEMS
} from "../types";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

var parseString = require("xml2js").parseString;

export function MenuItemsAction(itemConfiguracion, revenueCenter) {

	return async (dispatch) => {
		try {
			if (itemConfiguracion) {
				dispatch(comenzarDescarga());

				let data = {
					urlServer: itemConfiguracion.UrlServer,
					revenueCenter: revenueCenter || itemConfiguracion.RevenueCenter, 
					employeeObjectNum: itemConfiguracion.EmployeeObjectNum,
				};

				let config = {
					headers: {
						"Content-Type": "application/json",
					},
				};

				const respuesta = await clienteAxios.post(
					`/MenuItem?item=Menu`,
					data,
					config
				);

				let originalClass = respuesta.data["soap:Envelope"]["soap:Body"]["GetConfigurationInfoResponse"]["configInfoResponse"]["MenuItemClass"];
				let menuItemDefinitions = respuesta.data["soap:Envelope"]["soap:Body"]["GetConfigurationInfoResponse"]["configInfoResponse"]["MenuItemDefinitions"];
				let menuItemPrice = respuesta.data["soap:Envelope"]["soap:Body"]["GetConfigurationInfoResponse"]["configInfoResponse"]["MenuItemPrice"];

				parseString(originalClass, function (err, resClass) {
					originalClass = resClass["ArrayOfDbMenuItemClass"]["DbMenuItemClass"]
				});
				parseString(menuItemDefinitions, function (err, resDefinitions) {
					menuItemDefinitions = resDefinitions["ArrayOfDbMenuItemDefinition"]["DbMenuItemDefinition"]
				});
				parseString(menuItemPrice, function (err, resPrices) {
					menuItemPrice = resPrices["ArrayOfDbMenuItemPrice"]["DbMenuItemPrice"]
				});

				let menuItemClass = []
				let nombres = []

				menuItemDefinitions.map(async (itemMenu) =>{
					if(nombres.includes(itemMenu.Name3[0].StringText[0].trim())){
						let items = menuItemClass.filter(itemClass => itemClass.clase == itemMenu.Name3[0].StringText[0].trim())
						items[0].platos.push(itemMenu)
					}else{
						let newObj = {
							clase: itemMenu.Name3[0].StringText[0].trim(),
							platos: [itemMenu]
						}
						menuItemClass.push(newObj)
						nombres.push(itemMenu.Name3[0].StringText[0].trim())
					}
				})  

				menuItemClass = menuItemClass.sort((a, b) => ((a.clase.trim() > b.clase.trim()) ? 1 : ((a.clase.trim() <  b.clase.trim()) ? -1 : 0)))
				let object = { menuItemClass, menuItemDefinitions, menuItemPrice, originalClass }

				dispatch(menuItems(object));
			}
		} catch (e) {
			console.log(e)
			dispatch(errorDescarga());
		}
	};
}

const menuItems = (object) => ({
	type: DESCARGA_MENU_ITEMS,
	payload: object,
});

export function MenuCuentaAction(itemsCarrito, itemsConfiguracion, requerimientos, revenueCenter) {
	return async (dispatch) => {
		try {
			dispatch(comenzarDescarga());

			let xmlMenuItems = ""
			if (itemsCarrito) {
				itemsCarrito.map((item) => {
					let rep = 1
					while (rep <= item.Repetido) {
					let reqItem = requerimientos ? requerimientos.filter(req => req.PLatoId == item.MenuItemDefID[0]) : null
					xmlMenuItems += `<SimphonyPosApi_MenuItem>\
											${(reqItem && reqItem?.length) ? reqItem.map(req => 
												`<Condiments>\
													<SimphonyPosApi_MenuItemDefinition>\
														<ItemDiscount>\
															<DiscObjectNum>0</DiscObjectNum>\
														</ItemDiscount>\
														<MiObjectNum>${req.MiMasterObjNum[0]}</MiObjectNum>\
														<MiOverridePrice />\
														<MiReference />\
														<MiWeight />\
														<MiMenuLevel>1</MiMenuLevel>\
														<MiSubLevel>1</MiSubLevel>\
														<MiPriveLevel>0</MiPriveLevel>\
													</SimphonyPosApi_MenuItemDefinition>\
												</Condiments>`
												) : `<Condiments/>`}\
										<MenuItem>\
										<ItemDiscount>\
												<DiscObjectNum>0</DiscObjectNum>\
										</ItemDiscount>\
										<MiObjectNum>${item?.MiMasterObjNum ? item?.MiMasterObjNum[0] : item?.obj[0]}</MiObjectNum>\
										<MiReference />\
										<MiWeight />\
										<MiMenuLevel>0</MiMenuLevel>\
										<MiSubLevel>0</MiSubLevel>\
										<MiPriveLevel>0</MiPriveLevel>\
								</MenuItem>\
						</SimphonyPosApi_MenuItem>`
						rep++
					}

				})
			}
			let data = {
				urlServer: itemsConfiguracion.UrlServer,
				revenueCenter: revenueCenter || itemsConfiguracion.RevenueCenter,
				employeeObjectNum: itemsConfiguracion.EmployeeObjectNum,
				menuItems: xmlMenuItems,
			};

			let config = {
				headers: {
					"Content-Type": "application/json",
				},
			};

			const respuesta = await clienteAxios.post(
				`/MenuItem?item=Cuenta`,
				data,
				config
			);

			let TotalsResponse = respuesta.data["soap:Envelope"]["soap:Body"]["CalculateTransactionTotalsResponse"]
			dispatch(menuCuenta(TotalsResponse));
		} catch (e) {
			console.log(e)
			dispatch(errorDescarga());
		}
	};
}

export function AskWaiterAction(items, itemsConfiguracion, idMesaPedido, idCheckNum = null, idCheckSeq = null, revenueCenter, isOpenCheck = false) {
	return async (dispatch) => {
		try {
				if(items && items.length){
					let filtro = items.filter(item => item.Name1[0].StringText[0].toLowerCase().trim() === "llamar mesero" )

					let xmlMenuItems = `<SimphonyPosApi_MenuItem>\
											<Condiments />\
											<MenuItem>\
											<ItemDiscount>\
												<DiscObjectNum>0</DiscObjectNum>\
											</ItemDiscount>\
											<MiObjectNum>${filtro[0].MiMasterObjNum[0]}</MiObjectNum>\
											<MiOverridePrice />\
											<MiReference />\
											<MiWeight />\
											<MiMenuLevel>0</MiMenuLevel>\
											<MiSubLevel>0</MiSubLevel>\
											<MiPriveLevel>0</MiPriveLevel>\
									</MenuItem>\
							</SimphonyPosApi_MenuItem>`

					let data = {
						urlServer: itemsConfiguracion.UrlServer,
						revenueCenter: revenueCenter || itemsConfiguracion.RevenueCenter,
						employeeObjectNum: itemsConfiguracion.EmployeeObjectNum,
						numMesa: idMesaPedido,
						tMedia: "21",
						menuItems: xmlMenuItems
					};

					let config = {
						headers: {
							"Content-Type": "application/json",
						},
					};

					if((idCheckNum && idCheckNum) || isOpenCheck){
						data.checkSeq = idCheckSeq
						data.checkNum = idCheckNum
						await clienteAxios.put(
							`/MenuItem?item=AddToExistingCheck`,
							data,
							config
						)
					}else{
						data.idCheckSeq = idCheckSeq
						data.idCheckNum = idCheckNum
						await clienteAxios.put(
							`/MenuItem?item=PostTransaction`,
							data,
							config
						);
					}

					Swal.fire({
					title: "Correcto",
					text: "Se ha realizado la llamada al mesero.",
					icon: "success",
					confirmButtonText: "Ok.",
				});
			}
		} catch (e) {
			console.log(e);
			dispatch(errorDescarga());
		}
	};
}


const menuCuenta = (TotalsResponse) => ({
	type: CUENTA_MENU,
	payload: TotalsResponse,
});

const comenzarDescarga = () => ({
	type: COMENZAR_DESCARGA_ITEMS,
});

const errorDescarga = () => ({
	type: ERROR_DESCARGA,
	payload: true,
});
