import {
    DESCARGAR_IMAGENES,
    ACTUALIZAR_IMAGENES,
    AGREGAR_IMAGENES,
    ELIMINA_IMAGENES,
    DESCARGAR_IMAGENES_BANNER,
    DESCARGAR_IMAGENES_LOGO
} from "../types";

const initialState = {
    error: false,
    loading: false,
    itemsImagenes: null,
    itemsImagenesBanner: null,
    itemsImagenesLogo: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AGREGAR_IMAGENES:
            return {
                ...state,
                loading: false,
                error: false,
                itemsImagenes: [...state.itemsImagenes, action.payload]
            };
        case ACTUALIZAR_IMAGENES:
            return {
                ...state,
                loading: false,
                items: state.items.filter(
                    (item) => ((item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] && item.additions !== action.payload.additions) || (item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]))),
            };
        case DESCARGAR_IMAGENES:
            return {
                ...state,
                loading: false,
                error: false,
                itemsImagenes: action.payload
            };
        case DESCARGAR_IMAGENES_BANNER:
            return {
                ...state,
                loading: false,
                error: false,
                itemsImagenesBanner: action.payload
            };
        case DESCARGAR_IMAGENES_LOGO:
            return {
                ...state,
                loading: false,
                error: false,
                itemsImagenesLogo: action.payload
            };

        case ELIMINA_IMAGENES:
            return {
                ...state,
                loading: false,
                items: state.items.filter(
                    (item) => ((item.MenuItemDefID[0] === action.payload.MenuItemDefID[0] && item.additions !== action.payload.additions) || (item.MenuItemDefID[0] !== action.payload.MenuItemDefID[0]))),
            };
        default:
            return state;
    }
}
