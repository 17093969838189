import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useSelector, useDispatch } from "react-redux";
import { qrCreateActions } from "../../redux/actions/qrActions";
import Swal from "sweetalert2";
import QRcode from 'qrcode.react';
import { ConfigurationAction } from "../../redux/actions/configurationActions";


const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};
const obligatorio = {
    color: "#dc2626"
};


const ModalQr = ({ openModal, setOpenModal }) => {
    
    const dispatch = useDispatch();
    const [numeroMesa, setNumeroMesa] = useState('');
    const [revenueCenter, setRevenueCenter] = useState('');
    const [urlQr, setUrlQr] = useState('');
    const itemsConfiguracion = useSelector((state) => state.configurationsR.itemsConfiguration);

    const handlerClick = () => {

        if (numeroMesa == "" || urlQr == "") {
            Swal.fire(
                '¡Alerta!',
                'Algunos campos son obligatorios.',
                'warning'
            );
        } else {
            dispatch(qrCreateActions(revenueCenter || itemsConfiguracion?.RevenueCenter, numeroMesa, urlQr));
            setOpenModal(false);
        }

    };

    useEffect(() => {
        const cargarConfiguracion = () => dispatch(ConfigurationAction());
        if (!itemsConfiguracion) cargarConfiguracion();
        //eslint-disable-next-line
    }, [])

    return (
        <div style={styles}>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                center={true}
                style
            >

                <div className="styleModal">
                    <h2 className="font-bold mb-5">Creación De QR</h2>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-2">
                        <label className="self-center"><span style={obligatorio}>*</span> Número de Mesa: </label>
                        <input
                            name="numeroMesa"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Número de Mesa"
                            id="idNumeroMesa"
                            value={numeroMesa}
                            onChange={event => setNumeroMesa(event.target.value)}
                        />
                        <label className="self-center"> Revenue Center: </label>
                        <input
                            name="revenueCenter"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Revenue Center"
                            id="idRevenueCenter"
                            value={revenueCenter}
                            onChange={event => setRevenueCenter(event.target.value)}
                        />
                        <label className="self-center"><span style={obligatorio}>*</span> Url: </label>
                        <input
                            name="Url"
                            className="rounded border-2 p-2 text-gray-700 leading-tightfocus:outline-none focus:shadow-lg"
                            placeholder="Url"
                            id="idUrl"
                            value={urlQr}
                            onChange={event => setUrlQr(event.target.value)}
                        />
                    </div>

                    <div className="grid grid-cols-1 gap-4 justify-items-center mt-3">
                        {
                            urlQr && numeroMesa ? 
                                <QRcode
                                    value={urlQr + "?idMesa=" + numeroMesa + "&revenueCenter=" + (revenueCenter || itemsConfiguracion?.RevenueCenter || '')}
                                    size={320}
                                    includeMargin={true}
                                /> :
                                <p>No hay vista previa del QR.</p>
                        }
                    </div>
                    <div className="flex justify-end">

                        <div className="flex">

                            <button
                                style={{ marginTop: "50px" }}
                                className={`py-1 text-red-600 focus:outline-none hover:text-red-400 px-4`}
                                onClick={() => setOpenModal(false)}>Cancelar
                            </button>

                            <button
                                style={{ marginTop: "50px" }}
                                className={`px-3 rounded-md py-2 text-white bg-blue-600 focus:outline-none hover:bg-blue-500`}
                                onClick={handlerClick} >Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalQr;
