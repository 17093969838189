import {
    DESCARGAR_IMAGENES,
    ACTUALIZAR_IMAGENES,
    AGREGAR_IMAGENES,
    ELIMINA_IMAGENES,
    DESCARGAR_IMAGENES_BANNER,
    DESCARGAR_IMAGENES_LOGO

} from "../types";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export function ImagenesAction() {
    return async (dispatch) => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Config?item=GetImages`,
                config
            );

            let auxImagenes = respuesta.data;

            dispatch(descargarImagen(auxImagenes));

        } catch (error) {
        }
    };
}

export function ImagenesBannerAction() {
    return async (dispatch) => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Config?item=GetImages`,
                config
            );

            let auxImagenes = respuesta.data;

            if (auxImagenes != null) {
                let auxBanner = auxImagenes.filter(itemBanner => {
                    return itemBanner.Tipo == "Banner" && itemBanner
                });
                if (auxBanner.length != 0) {
                    dispatch(descargarImagenBanner(auxBanner));
                }
            }


        } catch (error) {
        }
    };
}

export function ImagenesLogosAction() {
    return async (dispatch) => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Config?item=GetImages`,
                config
            );

            let auxImagenes = respuesta.data;

            if (auxImagenes != null) {
                let auxLogo = auxImagenes.filter(itemLogo => {
                    return itemLogo.Tipo == "Logo" && itemLogo
                });
                dispatch(descargarImagenLogos2(auxLogo));
            }

        } catch (error) {
        }
    };
}




// export function imagenUpdateActions(id, tipo, imagen) {
//     return async (dispatch) => {
//         try {

//             let data = {
//                 admUserName: "Jeduca",
//                 admPassword: "SjNkdWNAMjAyMSo=",
//                 id: idUpdate,
//                 pass: passwordUpdate,
//                 role: roleUpdate,
//                 fullName: fullNameUpdate,
//                 userImage: userImageUpdate,

//             };

//             let config = {
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             };

//             const respuesta = await clienteAxios.post(
//                 `/Users?item=Update`,
//                 data,
//                 config
//             );
//             Swal.fire({
//                 title: "Correcto",
//                 text: "El usuario se ha actualizado con éxito.",
//                 icon: "success",
//                 confirmButtonText: "Ok.",
//             }).then((result) => {
//                 if (result.value) {
//                     window.location.href = window.location.href;
//                 }
//             });
//         } catch (error) {

//         }
//     };
// }



export function imageCreateActions(tipoImage, image) {
    return async (dispatch) => {
        // try {
        let data = {

            admUserName: "Jeduca",
            admPassword: "SjNkdWNAMjAyMSo=",
            tipo: tipoImage,
            url: image
        };

        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const respuesta = await clienteAxios.put(
            `/Config?item=AddImage`,
            data,
            config
        );

        Swal.fire({
            title: "Correcto",
            text: "La imagen se ha cargado con éxito.",
            icon: "success",
            confirmButtonText: "Ok.",
        }).then((result) => {
            if (result.value) {
                window.location.href = window.location.href;
            }
        });

        // } catch (error) {
        // }
    };
}


export function imagenDeleteActions(idUserDelete) {
    return async (dispatch) => {

        try {
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                idImage: idUserDelete
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.put(
                `/Config?item=DeleteImage`,
                data,
                config
            );

            Swal.fire({
                title: "Correcto",
                text: "La imagen se ha eliminado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });

        } catch (error) {
        }

    };
}


const actualizarUsuario = (usuarioItem) => ({
    type: ACTUALIZAR_IMAGENES,
    payload: usuarioItem
});

const agregarUsuario = (usuarioItem) => ({
    type: AGREGAR_IMAGENES,
    payload: usuarioItem
});

const descargarImagen = (imagenItem) => ({
    type: DESCARGAR_IMAGENES,
    payload: imagenItem
});

const descargarImagenBanner = (imagenBannerItem) => ({
    type: DESCARGAR_IMAGENES_BANNER,
    payload: imagenBannerItem
});
const descargarImagenLogos = (imagenLogoItem) => ({
    type: DESCARGAR_IMAGENES_LOGO,
    payload: imagenLogoItem
});

const descargarImagenLogos2 = (imagenLogoItem2) => ({
    type: DESCARGAR_IMAGENES_LOGO,
    payload: imagenLogoItem2
});

const eliminarUsuario = (usuarioItem) => ({
    type: ELIMINA_IMAGENES,
    payload: usuarioItem
});
