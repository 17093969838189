import {
    DESCARGAR_QR,
    ACTUALIZAR_QR,
    AGREGAR_QR,
    ELIMINA_QR

} from "../types";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export function QrAction() {
    return async (dispatch) => {
        try {

            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo="
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Config?item=GetUrlQr`,
                data,
                config
            );

            dispatch(descargarQr(respuesta.data));

        } catch (error) {
        }
    };
}

export function qrUpdateActions(RevenueCenter, mesa, UrlQr, idQr) {
    return async (dispatch) => {
        try {
            
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                revenueCenter: RevenueCenter,
                urlQr: UrlQr,
                mesa,
                idQr
            };
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.put(
                `/Config?item=UpdateQr`,
                data,
                config
            );
            Swal.fire({
                title: "Correcto",
                text: "El QR se ha actualizado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });
        } catch (error) {
        }
    };
}



export function qrCreateActions(RevenueCenter, mesa, UrlQr) {
    return async (dispatch) => {
        try {
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                revenueCenter: RevenueCenter,
                urlQr: UrlQr,
                mesa 
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.put(
                `/Config?item=AddQr`,
                data,
                config
            );

            Swal.fire({
                title: "Correcto",
                text: "El QR se ha creado en el sitema con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });

        } catch (error) {
        }
    };
}


export function qrDeleteActions(idQr) {
    return async (dispatch) => {

        try {
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                idQr: idQr
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.put(
                `/Config?item=DeleteQr `,
                data,
                config
            );

            Swal.fire({
                title: "Correcto",
                text: "El qr se ha eliminado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });

        } catch (error) {
        }

    };
}


const actualizarQr = (qrItem) => ({
    type: ACTUALIZAR_QR,
    payload: qrItem
});

const agregarQR = (qrItem) => ({
    type: AGREGAR_QR,
    payload: qrItem
});

const descargarQr = (qrItem) => ({
    type: DESCARGAR_QR,
    payload: qrItem
});

const eliminarQr = (qrItem) => ({
    type: ELIMINA_QR,
    payload: qrItem
});