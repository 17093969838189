import {
    DESCARGAR_USUARIO,
    ACTUALIZAR_USUARIO,
    AGREGAR_USUARIO,
    ELIMINA_USUARIO

} from "../types";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export function UsuarioAction() {
    return async (dispatch) => {
        try {

            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo="
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Users?item=GetUsers`,
                data,
                config
            );

            let aux = respuesta.data;
            dispatch(descargarUsuario(aux));

        } catch (error) {
        }
    };
}

export function usuarioUpdateActions(idUpdate, passwordUpdate, roleUpdate, fullNameUpdate, userImageUpdate, userInfoStorage) {
    return async (dispatch) => {
        try {

            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                id: idUpdate,
                pass: passwordUpdate,
                role: roleUpdate,
                fullName: fullNameUpdate,
                userImage: userImageUpdate,
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Users?item=Update`,
                data,
                config
            );

            if(idUpdate === userInfoStorage.Id){
                userInfoStorage.Nombre = fullNameUpdate;
                userInfoStorage.Rol = roleUpdate;
                userInfoStorage.Url = userImageUpdate ? userImageUpdate : userInfoStorage.Url;
                userInfoStorage.password = passwordUpdate.trim() == '' ? userInfoStorage.password : passwordUpdate;
            }
            localStorage.setItem("localUserObj", JSON.stringify(userInfoStorage));
            Swal.fire({
                title: "Correcto",
                text: "El usuario se ha actualizado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });
        } catch (error) {
        }
    };
}



export function usuarioCreateActions(userName, password, role, nameUser, image) {
    return async (dispatch) => {
        try {
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                userName: userName,
                pass: password,
                role: role,
                fullName: nameUser,
                userImage: image,
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Users?item=Create`,
                data,
                config
            );

            Swal.fire({
                title: "Correcto",
                text: "El usuario se ha creado en el sitema con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });

        } catch (error) {
        }
    };
}


export function usuarioDeleteActions(idUserDelete) {
    return async (dispatch) => {

        try {
            let data = {
                admUserName: "Jeduca",
                admPassword: "SjNkdWNAMjAyMSo=",
                id: idUserDelete
            };

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const respuesta = await clienteAxios.post(
                `/Users?item=Delete`,
                data,
                config
            );

            Swal.fire({
                title: "Correcto",
                text: "El usuario se ha eliminado con éxito.",
                icon: "success",
                confirmButtonText: "Ok.",
            }).then((result) => {
                if (result.value) {
                    window.location.href = window.location.href;
                }
            });

        } catch (error) {
        }

    };
}


const actualizarUsuario = (usuarioItem) => ({
    type: ACTUALIZAR_USUARIO,
    payload: usuarioItem
});

const agregarUsuario = (usuarioItem) => ({
    type: AGREGAR_USUARIO,
    payload: usuarioItem
});

const descargarUsuario = (usuarioItem) => ({
    type: DESCARGAR_USUARIO,
    payload: usuarioItem
});

const eliminarUsuario = (usuarioItem) => ({
    type: ELIMINA_USUARIO,
    payload: usuarioItem
});